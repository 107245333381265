import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { LaborAffilication } from "../../../utils/const";
import { useContext, useEffect, useRef, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputField from "../../InputField";
import { getOrgClassification } from "../../../utils/api";
import Loader from "../../Loader";


const ClassiFicationDetails = ({ formikProps }: any) => {

  const [isLoading, setIsLoading] = useState(false)
  const { values, handleChange, setFieldValue, errors, setFieldError, touched } = formikProps;
  const LaborAffiliations = LaborAffilication;
  const dateRef: any = useRef();

  useEffect(() => {
    if (!values.laborAffiation)
      setFieldError("laborAffiation", "Required!");
  }, []);

  useEffect(() => {
    if (values.classifications.length === 0) {
      setIsLoading(true);
      getOrgClassification("", undefined, undefined).then((response) => {
        const data = response.data.classification.map((value: any) => ({
          ...value,
          isSelected: false,
        }));
        console.log("data", data);
        setFieldValue("classifications", data);
      })
        .catch((error: any) => {
          console.log('error', error)
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <>
      <div className="row  gap-1 mt-3">
        <div className="form-floating col-3 offset-2">
          <FormControl fullWidth>
            <InputLabel
              id="select-label"
              size="small"
              required
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                fontFamily: "SenticosansDTCond Thin",
              }}
            >
              Labor Affiliation
            </InputLabel>
            <Select
              labelId="select-label"
              id="laborAffiation"
              name="laborAffiation"
              value={values.laborAffiation}
              label="Labor Affiliation"
              className="bg-white"
              onChange={handleChange}
              size="small"
              inputProps={{}}
            >
              <MenuItem value="" key={-1}></MenuItem>
              {LaborAffiliations.map((laborAff: any, index: any) => (
                <MenuItem key={index} value={laborAff}>
                  {laborAff}
                </MenuItem>
              ))}
            </Select>
            {touched.laborAffiation && errors.laborAffiation && (
              <span className="text-danger">{errors.laborAffiation}</span>
            )}
          </FormControl>
        </div>
        <div className="col-2">
          <InputField
            id="dirNumber"
            name="dirNumber"
            label="DIR Number"
            type="text"
            value={(values.dirNumber ?? '').toString().replace(/[^0-9]/g, '')}
            className="form-control"
            handleChange={handleChange}
            error={touched.dirNumber && errors.dirNumber}
          />
        </div>
        <div className="col-3">
          <TextField
            ref={dateRef}
            id="dirDate"
            name="dirDate"
            label="DIR Expiry date"
            type="date"
            className="form-control"
            value={values.dirDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true
            }}
            style={{ width: "100%", marginTop: 0 }}
            inputProps={{
              min: new Date().toISOString().split("T")[0],
              pattern: "\\d{4}-\\d{2}-\\d{2}",
              title: "Please enter a date in the format MM-DD-YY",
            }}
            fullWidth
            size="small"
          />
          {touched.dirDate && errors.dirDate && (
            <span className="text-danger">{errors.dirDate}</span>
          )}
        </div>
      </div>
      <div className="row justify-content-center gap-1 mt-5 ms-5">
        <h4 className="opacity-70 p-0" style={{ fontSize: "24px" }}>
          Classifications:
          {errors.classifications && (
            <span className="ms-2  text-danger size-lg">{errors.classifications as any}</span>
          )}
        </h4>
        {isLoading ? (
          <Loader className="d-flex justify-content-center mt-5 py-5 px-5" label="Fetching Classifications" />
        ) : (
          values.classifications.map((classFication: any, index: number) => (
            <div className="col-3">
              <FormControlLabel
                labelPlacement="end"
                className="size-4x"
                control={
                  <Checkbox
                    className="theme-check size-sm"
                    checked={classFication.isSelected}
                    name={classFication.classificationCode}
                    value={!classFication.isSelected}
                    onChange={(e: any) => {
                      setFieldValue(
                        `classifications[${index}].isSelected`,
                        !classFication.isSelected
                      );
                    }}
                    size="small"
                  />
                }
                label={classFication.classificationCode}
              />
            </div>
          ))
        )}
        <div className="col-3"></div>
      </div>
      {}
    </>
  );
}

export default ClassiFicationDetails;
