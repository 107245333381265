import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";

interface Type {
  title: string;
  isLogoVisible?: boolean | undefined
}

const GlobalHeader: React.FC<Type> = ({ title , isLogoVisible}: Type) => {
  return (
    <>
      <div className="container-fluid overflow-hidden" >
        {/* {isLogoVisible &&
          <header className="row p-2" >
            <div className="d-flex justify-content-start">
              <img
                src="BW logo-blue bg-svg.svg"
                width='310'
                height='56'
                alt="Login page"
                onError={(e) => {
                  const img = e.target as HTMLImageElement;
                  img.src = "image-not-found.png";
                }}
              />
            </div>
          </header>} */}
        <div className="row">
          {title  ? (
            <div className="d-flex  mt-1 align-items-start">
            <Link to={"/login"}>
                <FontAwesomeIcon
                className="theme-color pointer mt-1"
                  icon={faArrowLeft}
                  size="2x"
                  style={{ cursor: "pointer" }}
                />
              </Link>
              <h3 className=" mt-1 ms-2 size-2xl">{title}</h3>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default GlobalHeader;