import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import Modal from "react-modal";
import { CustomTable } from "../DataTable";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/selectors/selectors";

const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
      opacity: "1",
      background: "#f8f9fa",
      zIndex: "0",
    },
  };


type divisionType = {
    id: any;
    name: string;
    parenttrade: any;
    tradeCode: string;
  };
  
  type addDivisionType ={
    addDivisionList:divisionType[];
    editDivisionList:{ id: any;
      name: string;}[];
    deletedIdsList: any[];
  }
  
  const defaultDivision = {
    addDivisionList:[],
    editDivisionList:[],
    deletedIdsList:[]
  }

  interface Props {
    openModal: boolean;
    handleClose: () => void;
    handleSubmitNotAddedTrades:(addDivisionType : addDivisionType) => void
    allTradesRows: any;
  }
const PubicBidsOppTradeModal = ({openModal,handleClose,allTradesRows,handleSubmitNotAddedTrades} : Props) => {
    

    const [isProceed, setIsProceed] = useState(false);
    const [saveSelectedTrades, setSaveSelectedTrades] = useState<addDivisionType>(defaultDivision);


    const allTradeHeaderCells = [
      { id: "tradeCode", name: "Specs #", action: "leftAlign" },
      { id: "name", name: "Spec title", action: "leftAlign" },
      { id: "action", name: "Selected Trades", action: "tradeCheckBox" },
    ];
    const color = useSelector(selectThemeColor);

    const handleCheck = (row: any, checked: boolean) => {
      console.log("row", row);
      console.log("checked", checked);
      if (checked) {

        let newDivision = {
          id: row["id"],
          name: "",
          parenttrade: null,
          tradeCode: "",
          subDivisionList: [],
        };

        let updatedAddDivisionList = [
          ...saveSelectedTrades.addDivisionList,
          newDivision,
        ];

        setSaveSelectedTrades({
          ...saveSelectedTrades,
          addDivisionList: updatedAddDivisionList,
        });
      }
    };

    return (
      <Modal
        id="succes"
        isOpen={openModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="w-100 d-flex justify-content-end">
                <FontAwesomeIcon
                  className="btn-close pointer"
                  icon={faClose}
                  size="lg"
                  onClick={() => {
                    setIsProceed(false);
                    handleClose();
                  }}
                />
              </div>
            </div>
            <div className="modal-body mt-3">
              <>
                {!isProceed ? (
                  <Typography className="mb-2" variant="body1">
                    Oops! It looks like you haven't subscribed to any trades for
                    this project. To move forward, please select at least one
                    trade from the list and continue.
                  </Typography>
                ) : (
                  <CustomTable
                    headCells={allTradeHeaderCells}
                    rows={allTradesRows.filter(
                      (trade: any) => trade.isAdded === "N"
                    )}
                    handleCheck={handleCheck}
                  />
                )}
                <div className="row gx-0 mt-3">
                  <div className="col-4 offset-3">
                    <Button
                      className="btn col-8 ms-1"
                      onClick={() => {
                        setIsProceed(false);
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="col-4">
                    <Button
                      className="btn col-8"
                      onClick={() =>
                        !isProceed
                          ? setIsProceed(true)
                          : handleSubmitNotAddedTrades(saveSelectedTrades)
                      }
                    >
                      {!isProceed ? "Proceed" : "Submit"}
                    </Button>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </Modal>
    );
}

export default PubicBidsOppTradeModal