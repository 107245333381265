import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LinearProgress, Paper } from "@mui/material";
import {useState } from "react";
import CustomTablePagination from "./customTablePagination";
import RowDataTable from "./rowDataTable";
import { StyledTableCell } from "../../customeTheme";

interface Props {
  rows: any[];
  headers: any[];
  style?: any;
  handleDelete?: (projectDocId: number) => void;
  handleDocsStatus?: (projectDocId: any, status: string) => void;
  isEdit:boolean;
  toShowPagination?:boolean;
  maxHeight?: string | number | undefined;
  isLoading?:boolean;
}

const ProjectDocumentTable = ({
  rows,
  headers,
  style,
  handleDelete,
  isEdit,
  handleDocsStatus = (projectDocId: any, status: string) => null,
  toShowPagination=true,
  maxHeight=200,
  isLoading=false
}: Props) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);    console.log('ev', event.target.value)
    setPage(0);
  };

  return (
    <>
      <Paper className="shadow p-0" sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer key="table-container" sx={{ maxHeight: maxHeight }}>
          <Table
            key="table"
            className=""
            size="small"
            aria-label="data table"
            stickyHeader={true}
          >
            <TableHead key="table-head">
              <TableRow key="table-row" style={{ zIndex: 1 }}>
                {headers.map((cell, index) => (
                  <StyledTableCell
                    key={index}
                    style={{
                      width: 300,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    align="center"
                  >
                    {<span>{cell.name}</span>}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              key="body"
              sx={{
                overflowY: "scroll",
              }}
            >
              {(rows.length > 0) ? (
                (rowsPerPage > 0 && toShowPagination
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row, rowIndex) =>
                    <RowDataTable
                      key={rowIndex}
                      headCells={headers}
                      row={row}
                      rowIndex={rowIndex}
                      handleDelete={handleDelete}
                      handleDocsStatus={handleDocsStatus}
                      isEdit={isEdit}
                      isLoading={isLoading}
                    />
                )
              ) : isLoading ? (
                <TableRow key={"-1"}>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ textAlign: "center" }}
                  >
                    <LinearProgress style={{ background: "#19255c" }} />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={"-1"}>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ textAlign: "center" }}
                  >
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {toShowPagination && rows.length !== 0 && (
          <CustomTablePagination
            headCells={headers}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </>
  );
};

export default ProjectDocumentTable;
