import { useState } from "react";
import { Link } from "react-router-dom";
import GlobalHeader from "../GlobalHeader/globalHeader";
import { Button, Typography } from "@mui/material";
import InputField from "../InputField";
import { resentEmailVarification } from "../../utils/api";
import CustomizedSnackbar from "../CustomizedSnackbar";
import UseFormikValidation from "../../hooks/useFormValidation";
import { ForgotPasswordValidationSchema } from "../../utils/validateSchemaUtil";

const ForgotPassword = () => {
  
  const [open, setOpen] = useState(false);
  const [showSnackBar, setShowsnackBar] = useState(false);
  const [message, setMessage] = useState("");
  
  const { values, errors, isValid, handleChange,setFieldTouched } = UseFormikValidation<any>({
    initialValues: { email: "" },
    validationSchema: ForgotPasswordValidationSchema,
    validateOnMount: false,
  });

  const handleResentEmail = () => {
    resentEmailVarification("",values.email,undefined)
    .then((response)=>{
      console.log(response,"res in api");
      setShowsnackBar(true);
      if(response.status==='OK'){
      setMessage(response.message);
    } else{
      setMessage("Bad Request");
    }
    })
    .catch((error : any)=>{
      setShowsnackBar(true);
      setMessage("Bad Request " + error.message );
    });
    setOpen(true);
  };

  return (
    <>
      <div
        className="d-flex justify-content-start"
        style={{ background: "#19255c" }}
      >
        <img
          src="BW logo-blue bg-svg.svg"
          width="310"
          height="56"
          alt="Login page"
          onError={(e) => {
            const img = e.target as HTMLImageElement;
            img.src = "image-not-found.png";
          }}
        />
      </div>
      <div
        className="password-setting row mx-auto"
        style={{ marginTop: "10%" }}
      >
        <GlobalHeader
          title={!open ? "Forgot password?" : ""}
          isLogoVisible={true}
        />
        {open ? (
          <>
            <div className="container mt-3 shadow">
              <div className="row">
                <p className="col-12 col-sm-12 col-md-12 col-lg-12 opacity-75 size-lg">
                  If an account exists for{" "}
                  <span className="text-primary opacity-100">
                    {values.email}{" "}
                  </span>
                  , we have sent instructions for resetting your password.
                  Didn’t receive the email? Double-check the email address or
                  request to resend the instructions.
                </p>
              </div>
              <div className="row gx-0">
                <Link className="text-white text-decoration-none" to={"/login"}>
                  <Button className="btn btn-bg col-3 mt-3 py-2">
                    Back to Sign in
                  </Button>
                </Link>
              </div>
              <div className="row">
                <Link
                  className="col-5 m-1 fs-6 px-2"
                  to={"/forget-password"}
                  onClick={handleResentEmail}
                >
                  Resend the instruction again
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div className="container shadow bg-light">
            <div className="row">
              <Typography
                className="col-12 col-sm-12 col-md-12 col-lg-12 opacity-50 mt-2 d-flex justify-content-center"
                paragraph={true}
                fontSize={"18px"}
                style={{ fontFamily: "SenticosansDTCond Thin" }}
              >
                Enter the email address you registered, and we'll send you a
                reset password instruction via email.
              </Typography>
            </div>
            <div className="row mt-3 gx-0">
              <form className="form-group">
                <div className="col-8 col-sm-8 col-md-8 col-lg-8 mx-auto">
                  <InputField
                    id="email"
                    name="email"
                    value={values.email}
                    type="text"
                    handleChange={handleChange}
                    error={errors.email}
                    className="form-control"
                    label="Email"
                    required
                  />
                </div>
                <div className="row mt-3">
                  <Button
                    className="btn btn-bg col-3 col-sm-3 col-md-3 col-lg-3 mx-auto mt-3 mb-3"
                    onClick={(e) => {
                      e.preventDefault();
                     values.email
                       ? handleResentEmail()
                       : setFieldTouched("email", true);
                    }}
                    disabled={!isValid}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <CustomizedSnackbar
        openSnackBar={showSnackBar}
        message={message}
        handleCloseSnackBar={() => setShowsnackBar(false)}
      />
    </>
  );
};

export default ForgotPassword;
