import {
  Box,
  Tab,
  Badge,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Add } from "@material-ui/icons";
import SelectDivisionSpecialization from "../../SelectDivisionAndSpecification/selectDivisionAndSpecification";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { useCallback, useEffect, useState } from "react";
import TabPanel from "@mui/lab/TabPanel";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import { useSelector } from "react-redux";
import {
  selectProjectId,
  selectThemeColor,
} from "../../../redux/selectors/selectors";
import ProjectBidsForm from "../ProjectBidsForm/projectBidsForm";
import ProjectDocument from "../ProjectDocument/projectDocument";
import ProjectSubList from "../ProjectSubList/projectSubList";
import { useDispatch } from "react-redux";
import { setBidInvitationMailList, setCustomTableId, setProjectId } from "../../../redux/actions/projectFormActions";
import BidInviteModal from "../../BidInviteModal/bidInviteModal";
import {
  getAllSubsMailListForProject,
  getCountOfQueFromSubs,
  getCountOfSubReq,
  getAllQuestionsFromSubs,
  getSubRequestList,
  sendInviteSubContractor,
  updateSubReqStatus,
} from "../../../utils/api";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { SubRequestAccessListType, SubsQueRow } from "../../../utils/types";
import { CustomTable } from "../../DataTable";
import QuestionFromSubs from "../QuestionFromSubs/questionFromSubs";
import SelectInputField from "../../SelectInputField/selectInputField";
import { DataUtil } from "../../../utils/dataUtils";

const ProjectBids = (props: any) => {

  const [tabValue, setTabValue] = useState<string>("details");
  const [showSnackBar, setShowsnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [toOpenEmailModal, setToOpenEmailModal] = useState(false);
  const [subMailsList, setSubMailsList] = useState<string[]>([]);
  const [queCount, setQueCount] = useState<number>(0);
  const [toOpenSubRequestModal, setToOpenSubRequestModal] = useState(false);
  const [subReqestListRows, setSubReqestListRows] = useState<SubRequestAccessListType[]>([]);
  const [subReqCount, setSubReqCount] = useState<number>(0);
  const [subQueModal, setSubQueModal] = useState<boolean>(false);
  const [subsQueRow, setSubsQueRow] = useState<SubsQueRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);


  const disptch = useDispatch();
  const projectId = useSelector(selectProjectId);

  const handleChange = (event: any, newValue: string) => {
    setTabValue(newValue);
  };

  const tabValues =  projectId ? [
    {label: `Project: ${props.projectName}`, value: "projectName"},
    {label: "Add Sub", value: "addSub"},
    {label: "Email", value: "email"},
    {label: "Reports", value: "reports"},
    {label: "Questions From Subs", value: "questionsFormSub"},
    {label: "Link To Project Files", value: "linkToProjectFiles"},
    {label: "Subs Request", value: 'subRequest'},
    {label: "Filter", value: 'filter'}
  ] : [];

  useEffect(() => {
    if (!projectId) {
      setTabValue("details");
    }else{
      getCountOfQueFromSubs("", projectId, undefined).then((resp) => {
        if (resp.status === "OK") {
          if (resp.data === null) {
            setQueCount(0);
          } else {
            console.log(resp.data);
            setQueCount(resp.data);
          }
        }
      });
    }
    fetchGetSubReqListApi("requested");
  }, [projectId]);

  useEffect(() => {
    if (projectId && !toOpenSubRequestModal) {
      getCountOfSubReq("", projectId, undefined).then((resp) => {
        setSubReqCount(resp.data);
        // if (resp.status === "OK") {
        //     setSubReqCount(resp.data);
        // }
      });
    }
  }, [projectId,toOpenSubRequestModal]);

  const fetchGetSubReqListApi = useCallback((status: string) => {
    if(projectId){
      setIsLoading(true);
      getSubRequestList("", { projectId, status: status }, undefined)
      .then((resp) => {
        if (resp === null || resp.data === new Map()) {
          return;
        }
        setSubReqestListRows(resp.data);
        let count = resp.data.filter((data: { status: string; })=> data.status === 'requested').length;
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    }
  
  }, []);

  const handleCloseQnAModal = ()=>{
    setSubQueModal(false);
    getCountOfQueFromSubs("", projectId, undefined).then((resp) => {
      if (resp.status === "OK") {
        if (resp.data === null) {
          setQueCount(0);
        } else {
          console.log(resp.data);
          setQueCount(resp.data);
        }
      }
    });
  }

  const tab = tabValues.map((tab) => {
    return (
      <>
        <Tab
          className="border p-3 ms-1"
          label={tab.label}
          value="0"
          icon={
            tab.value === "addSub" ? (
              <Add />
            ) : tab.value === "questionsFormSub" ? (
              <Badge
                className="size-sm px-1"
                badgeContent={queCount}
                color="secondary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                showZero
              ></Badge>
            ) : tab.value === "subRequest" ? (
              <Badge
                className="size-sm px-1"
                badgeContent={subReqCount}
                color="secondary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                showZero
              ></Badge>
            ) : (
              <></>
            )
          }
          iconPosition={tab.value === "addSub" ? "start" : "end"}
          style={{
            fontWeight: tab.value === "projectName" ? "bold" : "normal",
          }}
          onClick={() => handleTabClick(tab.value)}
        />
      </>
    );
  }); 

  const  handleTabClick = (value:string) =>  {
    if(value === 'email'){
      setToOpenEmailModal(true);
      getAllSubsMailListForProject("", projectId, undefined)
      .then((resp) => {
        if (resp === null || resp.data === new Map()) {
          return;
        }
        setSubMailsList(resp.data.subMailsList);
      })
      .catch(() => {
      });
    }else if(value === 'subRequest'){
      setToOpenSubRequestModal(true);
      fetchGetSubReqListApi("requested");
    }else if(value === 'questionsFormSub'){
      setSubQueModal(true);
      getAllQuestionsFromSubs("", {projectId, sector:""}, undefined)
      .then((resp)=>{
        if(resp.status === 'OK'){
          console.log(resp.data);
          const updatedData = resp.data.map((item: any) => {
            const recDateTime: string[] = item.receivedDate.split(" ");
            const ansDateTime: string[] = item.answeredDate === null ? ["","",""] : item.answeredDate.split(" ");
          
            return {
              ...item,
              receivedDate: recDateTime[0] + " " +recDateTime[1] + " " + recDateTime[2],
              receivedTime: recDateTime[1] + " " + recDateTime[2],
              answeredDate: ansDateTime[0] + " " +ansDateTime[1] + " " + ansDateTime[2],
              answeredTime: ansDateTime[1] + " " + ansDateTime[2],
            };
          });
          
          setSubsQueRow(updatedData);
        }
      })
      .catch((error)=>{
        console.log("error",error);
  });
    }
  }
  const handleUpdateNewMails = (newMail : string) =>{
    let mailList = [...subMailsList,newMail]
    setSubMailsList(mailList);
  };

  const handleSelectedRow = (row :any) =>{
    setToOpenEmailModal(false);
  };

  const handleSendInvite = ({subject , emailScope, files,inviteType,subMails} : any) => {
    setIsLoading(true);
    const formData = new FormData();
    const body = { projectId, projectTradeId: null, subContractorMails : subMails, subject,emailBody: emailScope,inviteType:inviteType };
    formData.append('emailRequest', JSON.stringify(body));
    files.forEach((file: any, index: number) => {
      const { blob, name } = DataUtil.createNewBlocAndFileName(file, index);
      formData.append("emailAttachments", blob, name);
    });

    if (subMails.length > 0) {
      setShowsnackBar(true);
      sendInviteSubContractor("", undefined, formData)
        .then((response) => {
          setToOpenEmailModal(false);
          setMessage(response.message);
          setIsLoading(false);
          setShowsnackBar(true);
          setMessage(response.message);
        })
        .catch((error: any) => {
          setToOpenEmailModal(false);
          setIsLoading(false);
          setShowsnackBar(false);
          setMessage(error.message);
        });
    } else {
      setIsLoading(false);
      setMessage("Please Check invite mail");
    }
  };

  const handleBidReqStatusChange = (e: any) => {
    fetchGetSubReqListApi(e.target.value.toLowerCase());
  };

  const handleClickOnStatus = (row: any, status: string, checked: boolean) => {
    setIsLoading(true);
    disptch(setCustomTableId(row.subRequestId))
    updateSubReqStatus(
      "",
      { status: status, subRequestId: row["subRequestId"] },
      undefined
    )
      .then(() => {
        fetchGetSubReqListApi("requested");
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <>
        <TabContext value={0}>
          <div className="">
            <TabList
              aria-label=""
              sx={{
                color: "dark",
                borderRadius: "4px",
                "& .MuiTabs-indicator": {
                  backgroundColor: "transparent",
                },
              }}
              style={{ overflowY: "scroll" }}
            >
              <div className="col-10 offset-1 px-1">{tab}</div>
            </TabList>
          </div>
        </TabContext>
        <div className="d-flex h-100">
          <TabContext value={tabValue}>
            <Box sx={{ width: "10%", color: "black" }}>
              <TabList
                onChange={handleChange}
                aria-label=""
                sx={{
                  borderRadius: "4px",
                  "& .MuiTabs-indicator": {
                    backgroundColor: "black",
                  },
                }}
                orientation="vertical"
              >
                <Tab label="Trades" value="trades" disabled={!projectId} />
                <Tab label="Sublist" value="subList" disabled={!projectId} />
                <Tab label="Files" value="files" disabled={!projectId} />
                <Tab label="Details" value="details" />
              </TabList>
            </Box>
            <TabPanel
              className="p-0 h-75 w-100  shadow bg-light"
              value="trades"
            >
              <div style={{ overflowY: "scroll" }}>
                <SelectDivisionSpecialization
                  formikProps={{
                    showTradeLable: true,
                    setBackButton: props.setBackButton,
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel
              className="p-0 pt-3 h-75 w-100 shadow bg-light"
              style={{ overflow: "hidden" }}
              value="subList"
            >
              <div className="container-fluid">
                <ProjectSubList
                  key={tabValue}
                  headerCells={[]}
                  isEdit={false}
                  isView={false}
                  handleSelectedRow={handleSelectedRow}
                />
              </div>
            </TabPanel>
            <TabPanel
              className="p-0 pt-3 w-100 h-75 shadow bg-light"
              style={{ overflow: "hidden" }}
              value="files"
            >
              <div className="container-fluid">
                <ProjectDocument key={tabValue} />
              </div>
            </TabPanel>
            <TabPanel
              className="p-0 pt-3 w-100 h-75 shadow bg-light"
              style={{ overflow: "scroll" }}
              value="details"
            >
              <div className="container">
                <ProjectBidsForm
                  key={tabValue}
                  setBackButton={props.setBackButton}
                />
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </>

      <CustomizedSnackbar
        openSnackBar={showSnackBar}
        message={message}
        handleCloseSnackBar={() => setShowsnackBar(false)}
      />
      <BidInviteModal
        isOpen={toOpenEmailModal}
        handleClose={() => setToOpenEmailModal(false)}
        subMailsList={subMailsList}
        handleSubmit={handleSendInvite}
        showAllMails={true}
        handleUpdateNewMails={handleUpdateNewMails}
      />
      <SubRequestModal
        isOpen={toOpenSubRequestModal}
        handleClose={() => setToOpenSubRequestModal(false)}
        subReqestListRows={subReqestListRows}
        handleClickOnStatus={handleClickOnStatus}
        handleBidReqStatusChange={handleBidReqStatusChange}
        isLoading={isLoading}
      />
      <QuestionFromSubs
        isOpen={subQueModal}
        isSender={false}
        handleClose={handleCloseQnAModal}
        rowData={subsQueRow}
        projectName={props.projectName}
      />
    </>
  );
};

export default ProjectBids;


interface Props {
  isOpen: boolean;
  handleClose: () => void;
  subReqestListRows:SubRequestAccessListType[]
  handleUpdateNewMails?: (newMail: string) => void;
  isLoading?: boolean;
  handleClickOnPreview?: (viewMailPreview : boolean) => void;
  handleClickOnStatus?:(row :any, actionType:string,checked:boolean) => void;
  handleBidReqStatusChange?:(e : any)=> void;
}

const SubRequestModal = (
{  isOpen = false,
  handleClose,
  subReqestListRows,
  handleClickOnStatus,
  handleBidReqStatusChange,
  isLoading = false} : Props
) => {

  const color = useSelector(selectThemeColor);
  const headCells  = [
    { id: "date", name: "Date", action: "date" },
    { id: "orgName", name: "Company", action: "companyHover" },
    { id: "requestType", name: "Request Type", action: "", align : "left" },
    { id: "action", name: "Action", action: "subRequestAccess" },
  ];
  
  const style = [
    { width: "100%", overflowX: "hidden", overflowY: "scroll" },
  ];



  return (
    <Modal
      id="viewInvite"
      className="position-fixed m-2"
      isOpen={isOpen}
      ariaHideApp={false}
      style={{
        content: {
          position: "fixed",
          top: "auto",
          left: "auto",
          right: "0",
          bottom: "0",
          width: "700px",
          height: "535px",
          padding: "0",
          borderRadius: "10px",
          backgroundColor: "white",
          border: "1px solid #ccc",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
          transition: "width 0.3s ease, height 0.3s ease",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 1000,
          pointerEvents: "auto",
          transition: "background-color 0.3s ease, opacity 0.3s ease",
          opacity: 1,
        },
      }}
    >
      <div className="modal-dialog modal-2x">
        <div className="modal-content">
          <div className="modal-header row gx-0">
            <div className="col-6">
              <h5 className="opacity-70">Sub Contractor Request</h5>
            </div>
            <div className="col-4 d-flex justify-content-end">
              {isLoading ? (
                <></>
              ) : (
                <>
                  <FontAwesomeIcon
                    className="col-2 pointer"
                    icon={faClose}
                    size="xl"
                    color={color.mediumColor}
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end m-3">
            <SelectInputField
              id="Status"
              name="Status"
              label="Select Status"
              defaultValue="Requested"
              formControlClassName="col-4"
              labelClassName="bg-white"
              selectClassName="bg-white w-auto"
              menuClassName="d-block ms-3"
              menuList={["Accepted", "Declined", "Requested"]}
              handleChange={handleBidReqStatusChange}
            />
          </div>

          <div className="container">
            <CustomTable
              headCells={headCells}
              rows={subReqestListRows}
              style={style}
              onSelectRowClick={handleClickOnStatus}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
