export const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-80%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
    opacity: "1",
    background: "#f8f9fa",
  },
};


export const LaborAffilication = [
  "",
  "Union",
  "Non Union",
  "Prevailing Wage",
  "None of the above",
];

export const NewProjectSteps = [
  { label: "Basic Project Details", id: 0 },
  { label: "Documents", id: 1 },
  { label: "Trades", id: 2 },
  // { label: "Bids", id: 3 },
  // { label: "Finalizing Subcontrator", id: 2 },
];

export const ConstructionTypeList = [
  "Tenant Improvements (TI)",
  "Renovation/Remodel",
  "Site Improvement",
  "New Construction",
];

export const ProjectDocumentList = [
  { id: 1, value: "Plans" },
  { id: 2, value: "Specifications" },
  { id: 3, value: "Soil Report" },
  { id: 4, value: "Drawing" },
  { id: 5, value: "Pictures" },
  { id: 6, value: "Revisions" },
  { id: 7, value: "Others" },
];

export const RequirementList = [
  { id: 1, name: "DVBE Good Faith Efforts" },
  { id: 1, name: "Project Labor Agreement (PLA)" },
  { id: 1, name: "Community Workforce Agreement (CWA)" },
  { id: 1, name: "Skilled And Trained Workforce Requirements (STW)" },
];

export const OnboardFormSection = [
  { label: "Basic Details", id: 0 },
  { label: "Classifications & Other Details", id: 1 },
  { label: "Trades", id: 2 },
];

export const PostedByMeHeaderCells = [
  { id: "bidDate", name: "Bid Date & Time", action: "" },
  { id: "projectName", name: "Project Name", action: "click" },
  { id: "city", name: "City", action: "",align:'left' },
  { id: "status", name: "Status", action: "dropDown" },
];

export const InvitedByOtherHeaderCells = [
  { id: "Add to Self", name: "Add to Self", action: "addToSelf" },
  { id: "bidDate", name: "Bid Date & Time", action: "" },
  { id: "jobWalkDate", name: "Job Date & Time", action: "" },
  { id: "projectName", name: "Project Name", action: "click" },
  { id: "city", name: "City", action: "",align:'left' },
  { id: "invitedBy", name: "Invited By", action: "" ,align:'left' },
  //To be used afterwards
  //{ id: "status", name: "Status", action: "inviteDropDown" },
];

export const exploreOpprtunitiesheaderCells = [
  { id: "projectName", name: "Project Title", action: "",align:'left' },
  { id: "bidNumber", name: "Bid#", action: "",align:'left' },
  { id: "bidDate", name: "Bid Date & Time", action: "date" },
  { id: "jobWalkDate", name: "Job Date & Time", action: "date" },
  { id: "invitedBy", name: "Posted By", action: "",align:'left' },
  { id: "city", name: "City", action: "",align:'left' },
];

export const getUserHeaderCells = [
  { id: "first_name", name: "Name", action: "", align:"left" },
  { id: "email", name: "Email", action: "", align:"left" },
  { id: "phone_number", name: "Phone Number", action: "", align:"left" },
  { id: "status", name: "Status", action: "", align:"left" },
  { id: "action", name: "Action", action: "edit" },
];

export const exploreProjectSelectedRow = {
  id: 995563,
  projectName: "Lucy Street Construction, Grand Lake, CO",
  bidDate: "11-06-2023",
  budget: 86091,
  projectOwnerName: "US Department of Agriculture (USDA) DC",
  city: "New York",
  sector: "Private",
  division: "Fire Protection",
  specification: "Concrete : Material Processing and Handling Equipment",
  constructionType: "New Construction",
  projectVisibility: "Private",
  unitNumber: 1,
  address1: "1070 Killdeer Pass",
  address2: "6118 Steensland Road",
  state: "New York",
  zipcode: "8607",
  name: "Hobey",
  email: "hneale0@hhs.gov",
  phoneNo: "+07-5577071324",
  zipCode: "317743",
  discription: "Project description",
};

export const editOrganizationHeaderCells = [
  { id: "first_name", name: "Name", action: "", align:"left" },
  { id: "email", name: "Email", action: "", align:"left" },
  { id: "phone_number", name: "Phone Number", action: "", align:"left" },
  { id: "status", name: "Status", action: "", align:"left" },
];

export const organizationListHeaderCells = [
  { id: "org_name", name: "Organization Name", action: "", align:"left" },
  { id: "license_num", name: "License Number", action: "" , align:"left"},
  { id: "laborAffiliation", name: "Labor Affiliation", action: "", align:"left" },
  { id: "created_date", name: "Registration Date", action: "date" },
  { id: "action", name: "Action", action: "edit" },
];

export const ProjectDocumentHeaders = [
  { id: "docName", name: "Title", action: "title" },
  { id: "docName", name: "File Name", action: "" },
  { id: "size", name: "Size", action: "size" },
  { id: "bidDate", name: "Date", action: "", },
  { id: "action", name: "Action", action: "viewAndDelete" },
];

export const ProjectAddendaHeader = [
  { id: "addendaDate", name: "Date", action: "date" },
  { id: "addendaNumber", name: "Number", action: "" },
  { id: "addendaType", name: "Name", action: "" },
  { id: "docName", name: "File Name", action: "" },
  { id: "", name: "Action", action: "viewAndDelete" },
];

export const ProjectOthersHeader = [
  { id: "docName", name: "File Name", action: "" },
  { id: "size", name: "Size", action: "size" },
  { id: "", name: "Action", action: "viewAndDelete" },
];


export const BidStatus = [
  "Actively Bidding",
  "Post Bid",
  "Under Construction",
  "Closed",
  "Draft"
];

export const BuildingType = [
  "Automotive",
  "Aviation/Hangars",
  "Civil-Site Development",
  "Country Clubs-Hotels-Recreation",
  "Health Care-Medical",
  "Health/Fitness Center",
  "High-tech-R&D-Labs",
  "Industrial-Commercial",
  "Institutional-Schools",
  "Interiors-Tenant Improvements",
  "Municipal-Government-Civic",
  "Office Buildings",
  "Parking Structures",
  "Places of Worship",
  "Residential",
  "Restaurant",
  "Retail Centers",
  "Self-Storage",
  "Special Projects",
];

export const BidType = [
  "Competitive",
  "Construction Management (CM)",
  "Negotiated",
  "Prevailing Wage",
];

export const ProjectContact = ["Estimator", "Project Manager"];

export const InvitedByOthersData = [
  {
    bidDatetime: "2024-07-30 13:30 PM",
    jobWalkDatetime: "2024-07-30 12:27 PM",
    invitedProjectName: "qwertyuik",
    city: "New York City",
    invitedBy: "XYZTEST",
    action: "-",
  },
];

export const buttonGroupProject = [
  { label: "Bid Information", value: "bidInfo", headers: "" },
  { label: "Documents", value: "Document", headers: ProjectDocumentHeaders },
  { label: "Addenda / RFI Files", value: "Addenda", headers: ProjectAddendaHeader },
  { label: "Q&A", value: "qAndA", headers: ProjectOthersHeader },
  { label: "Photos", value: "Photo", headers: ProjectOthersHeader },
];

export const btnGrpForBidStatus = [
  {label: "Accept", value: "Accepted" },
  {label: "Reject", value: "Rejected" },
  {label: "MayBe", value: "May Be" } ];

export const InvitedTradesHeaders = [
  {id: "tradeName", name: "Trade Name", action: "" , align:"left"},
  {id: "tradeCode", name: "Trade Code", action: "",align:"left"},
  {id: "bidStatus", name: "Action", action: "bidStatusUpdate"},
];

export const submitTradeHeaderCells = [
  {id: "name", name: "Trade Name", action: ""},
  {id: "tradeCode", name: "Trade Code", action: "leftAlign"},
  {id: "actionTaken", name: "Action Taken", action: ""},
  {id: "action", name: "Action", action: "removeTrade"},
];

export const queFromSubsHeaders = [
  {id: "questionsCount", name: "Q#"},
  {id: "subject", name: "Subject"},
  {id: "askedBy", name: "Asked By"},
  {id: "attachmentCount", name: "Attachments"},
  {id: "status", name: "Status"},
  {id: "receivedDate", name: "Received Date"},
  {id: "answeredDate", name: "Answered Date"},
  {id: "conventBtn", name: "Convent Button"},
];

export const DocHeadersForQA = [
  { id: "docName", name: "File Name", action: "" },
  { id: "action", name: "Action", action: "viewAndDownload" },
];

export const cityNames = ["",
  "Abilene", "Akron", "Albuquerque", "Alexandria", "Allen", "Anaheim", "Antioch",
  "Austell", "Austin", "Augusta", "Bakersfield", "Baltimore", "Baton Rouge",
  "Beaumont", "Bend", "Billings", "Birmingham", "Boulder", "Bridgeport",
  "Brockton", "Brownsville", "Burbank", "Carmel", "Carlsbad", "Carrollton",
  "Chandler", "Charleston", "Chattanooga", "Chesapeake", "Chicago", "Cincinnati",
  "Clearwater", "Cleveland", "Colorado Springs", "Columbia", "Columbus", "Columbus",
  "Corona", "Corpus Christi", "Costa Mesa", "Dallas", "Davenport", "Dayton",
  "Dearborn", "Denver", "Denton", "Des Moines", "Downey", "Durham", "El Cajon",
  "El Paso", "Elgin", "Elk Grove", "Englewood", "Eugene", "Evansville", "Fairfield",
  "Fargo", "Fayetteville", "Fishers", "Fontana", "Fort Collins", "Fort Lauderdale",
  "Fort Worth", "Fresno", "Gainesville", "Garland", "Garden Grove", "Gilbert",
  "Glendale", "Grand Prairie", "Grand Rapids", "Green Bay", "Greensboro", "Greeley",
  "Hampton", "Hartford", "Hayward", "Henderson", "Hialeah", "Hollywood", "Honolulu",
  "Houston", "Huntington Beach", "Indianapolis", "Inglewood", "Irvine", "Jackson",
  "Jacksonville", "Jersey City", "Joliet", "Kansas City", "Killeen", "Kent",
  "Knoxville", "Lafayette", "Lakewood", "Lancaster", "Las Cruces", "Las Vegas", "Los Angeles",
  "Little Rock", "Long Beach", "Longmont", "Louisville", "Lubbock", "Lynn",
  "Madison", "Manchester", "McAllen", "McKinney", "Memphis", "Mesa", "Miami",
  "Miami Gardens", "Milwaukee", "Minneapolis", "Miramar", "Mobile", "Modesto",
  "Montgomery", "Moreno Valley", "Murfreesboro", "Naperville", "Nashville",
  "New Bedford", "New Haven", "New Orleans", "Newark", "New York City", "Norfolk",
  "North Charleston", "North Las Vegas", "Oklahoma City", "Olathe", "Oxnard",
  "Palm Bay", "Palmdale", "Pembroke Pines", "Peoria", "Philadelphia", "Phoenix",
  "Pittsburgh", "Plano", "Port St. Lucie", "Portland", "Providence", "Provo",
  "Quincy", "Rancho Cucamonga", "Raleigh", "Rancho Santa Margarita", "Renton",
  "Richmond", "Richardson", "Rochester", "Rockford", "Roseville", "Sacramento",
  "St. Louis", "Saint Paul", "Salem", "Salinas", "Salt Lake City", "San Antonio",
  "San Bernardino", "San Diego", "San Francisco", "San Jose", "Santa Ana",
  "Santa Clarita", "Santa Maria", "Santa Rosa", "Simi Valley", "Sioux Falls",
  "South Bend", "South Fulton", "Spokane", "Spokane Valley", "Springfield",
  "Sunnyvale", "Surprise", "Tacoma", "Tallahassee", "Temecula", "Tempe",
  "Texas City", "Thornton", "Torrance", "Trenton", "Tucson", "Tulsa",
  "Upland", "Vallejo", "Vancouver", "Ventura", "Virginia Beach", "Waco",
  "Warren", "Washington", "Wichita", "Wichita Falls", "Wilmington", "Woodbridge",
  "Yonkers"
];

export const SubmitProjectBidHeaders = [
  { id: "docName", name: "Title", action: "title" },
  { id: "action", name: "Action", action: "viewAndDelete" },
];

export const SubmitBidAddendaHeader = [
  { id: "", name: "Status", action: "check" },
  { id: "addendaNumber", name: "Number", action: "" },
  { id: "addendaType", name: "Name", action: "" },
  { id: "docName", name: "File Name", action: "" },
];

export const  BidsTableHeaderCells = [
  { id: "response", name: "Response", action: "number" },
  { id: "status", name: "Status", action: "",align:'left' },
  { id: "bidAmount", name: "Bid $", action: "",align:'left' },
  { id: "questions", name: "Question", action: "" },
  { id: "orgName", name: "Organization", action: ""},
  { id: "firstName", name: "SC Name", action: "" },
  { id: "mobileNo", name: "Phone", action: "phone" },
  { id: "email", name: "Email", action: "click" },
  { id: "city", name: "City", action: "" },
  { id: "state", name: "State", action: "" },
  { id: "classification", name: "Classification", action: "" },
  { id: "laborAffiliation", name: "Labor Affiliation", action: "" },
  { id: "bidPercentage", name: "Bid Percent", action: "" },
  { id: "action", name: "Action", action: "viewSubmitBidsDetails" },
];