import { useEffect, useState } from "react";
import { CustomTable } from "../../DataTable";
import { FormControl, Select, SelectChangeEvent } from "@mui/material";
import { InputLabel, MenuItem } from "@mui/material";
import { getOrgList, orgSearch } from "../../../utils/api";
import { Button } from "@mui/material";
import {
  LaborAffilication,
  organizationListHeaderCells,
} from "../../../utils/const";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../../redux/selectors/selectors";
import SelectInputField from "../../SelectInputField/selectInputField";

type Row = {
  orgId: any;
  org_name: string;
  license_num: string;
  created_date: string;
  laborAffiliation: string;
  action: string;
};

const OrganisationList = (props: any) => {
  const [rows, setRows] = useState<Row[]>([]);
  const [search, setSearch] = useState<any>("");
  const laborAffiliation = LaborAffilication;

  const color = useSelector(selectThemeColor);
  const style = [
    { width: "100%", height: "70vh", overflowX: "scroll", overflowY: "scroll" },
  ];

  useEffect(() => {
    getOrgList("", undefined, undefined)
      .then((response: any) => {
        console.log("object", response.data);
        if (response.status === "OK") {
          const data = orgList(response.data);
          console.log("data", data);
          setRows(data);
        } else {
          setRows([]);
        }
      })
      .catch((error: any) => {});
  }, []);

  const orgList = (data: any[]) => {
    return (
      data &&
      data.map((item: any) => ({
        orgId: item.orgId,
        org_name: item.orgName,
        license_num: item.licenseNumber,
        created_date: item.createdDate,
        laborAffiliation: item.laborAffiliation,
        city: item.city,
        state: item.state,
        classification: "Disadvantaged Business Enterprise",
        address1: item.address1,
        address2: item.address2,
        zipCode: item.zipCode,
        status: item.status,
        action: "edit",
      }))
    );
  };

  const handleSearch = () => {
    console.log("serach", search);
    if(search==="" || search==="All"){
      getOrgList("", undefined, undefined)
      .then((response: any) => {
        if(response.status.includes("Ok")){
          console.log("object", response.data);
          if (response.data) {
            const data = orgList(response.data);
            console.log("data", data);
            setRows(data);
          } else {
            setRows([]);
          }
        }
      })
      .catch((error: any) => {});
    }
    else{
    orgSearch("", search,undefined)
      .then((response) => {
        console.log("orgSearch", response);
        if (response.status === "OK") {
          const data = orgList(response.data);
          setRows(data);
        } else {
          setRows([]);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
    }
  };

  return (
    <>
      <div>
        <div className="row d-flex p-1" style={{ width: "100vw" }}>
          <div className="col-3 col-md-3 col-sm-3">
            <SelectInputField
              id="select_affiliation"
              name="select_affiliation"
              handleOnClick={(laborAff: any) => setSearch(laborAff)}
              label="Select Labor Affiliation"
              menuList={laborAffiliation}
            />
          </div>
          <div className="col-6"></div>
          <div className="d-flex col-3 justify-content-end">
            <Button
              className="btn col-8 col-md-8 col-sm-8 mt-1"
              style={{ backgroundColor: color.darkColor, color: "white" }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
        </div>
        <div className="row mt-3 gx-0">
          <div className="col-12 pr-4">
            <CustomTable
              headCells={organizationListHeaderCells}
              rows={rows}
              style={style}
              onSelectRowClick={(row: any) => {
                props.setBack(true);
                props.getSubMenu("/ Edit Organization");
                props.onEditSelect(row);
              }}
            />
          </div>
          <div className="col-1"></div>
        </div>
      </div>
    </>
  );
};
export default OrganisationList;