import { useCallback, useEffect, useState } from "react";
import { CustomTable } from "../../DataTable";
import InputField from "../../InputField";
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { getAllSelectedTrades, getBidOppBySearch, getBidsExploreOpportunities, putPublicBidRequestAccess, updateOrgTrades } from "../../../utils/api";
import { selectProjectId, selectThemeColor } from "../../../redux/selectors/selectors";
import { useSelector } from "react-redux";
import { cityNames } from "../../../utils/const";
import { SearchFilterBidOppType } from "../../../utils/types";
import { useDispatch } from "react-redux";
import { setProjectId } from "../../../redux/actions/projectFormActions";
import PubicBidsOppTradeModal from "../../PubicBidsOppTradeModal/pubicBidsOppTradeModal";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import SelectInputField from "../../SelectInputField/selectInputField";

const DefaultSearchFiltered = {
  projectName: "",
  dueDateFrom: "",
  dueDateTo: "",
  email: "",
  city: "",
}

const BidExploreOpportunities = () => {
  const [exploreOpportunitiesRowData, setExploreOpportunitiesRowData] =
    useState<any>([]);
  const [search, setSearch] = useState<SearchFilterBidOppType>(DefaultSearchFiltered);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [allTradesRows, setAllTradesRows] = useState<any>([]);
  const [isDueDate, setIsDueDate] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const color = useSelector(selectThemeColor);
  const projectId = useSelector(selectProjectId);

  const headCells = [
    { id: "projectName", name: "Project Title", action: "",align:'left' },
    { id: "bidNumber", name: "Bid#", action: "" ,align:'left'},
    { id: "bidDate", name: "Bid Date & Time", action: "date" },
    { id: "jobWalkDate", name: "Job Date & Time", action: "date" },
    { id: "invitedBy", name: "Posted By", action: "",align:'left' },
    { id: "city", name: "City", action: "",align:'left' },
    { id: "Request Access", name: "Action", action: "button" },
  ];

  const style = [
    { width: "100%", height: "65vh", overflowX: "scroll", overflowY: "scroll" },
  ];

  useEffect(() => {
    fetchGetBidsExpApi();
  }, []);

  const fetchApiGetAllProjectTrades = useCallback((projectId: any) : any => {
    setIsLoading(true);
    getAllSelectedTrades("", projectId, undefined)
      .then((resp) => {
        if (resp.data.AllTrades.length > 0) {
          setAllTradesRows(resp.data.AllTrades);
          const isAtleastOneTradeThere = resp.data.AllTrades.filter((data : any)=> data.isAdded === 'Y').length > 0;
          setIsOpenModal(!isAtleastOneTradeThere);
          if (isAtleastOneTradeThere) {
            putPublicBidRequestAccess("", projectId, undefined)
              .then((resp) => {
                if (resp.status === "OK") {
                  fetchGetBidsExpApi();
                  setMessage("Request is being processed...");
                  setIsOpen(true);
                  setIsLoading(false);
                }
              })
              .catch(() => {
                setIsLoading(false);
              });
          }
          setIsLoading(false);
          return isAtleastOneTradeThere
        }
      })
      .catch(() => {
        return false;
      });
  }, []);

  const fetchGetBidsExpApi = () => {
    getBidsExploreOpportunities(" ", undefined, undefined)
      .then((resp) => {
        if (resp.data === null || resp.data === new Map() || resp.data === undefined) {
          setExploreOpportunitiesRowData([]);
        } else {
          setExploreOpportunitiesRowData(resp.data);
        }
      })
      .catch((e: any) => {
        console.log("e error:>> ", e);
      });
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if(name === "dueDateFrom" && (value !== "" || value !== null)){
     setIsDueDate(true);
    }
    if(name === "email"){
      const changedvalue = value.replace(/[^a-zA-Z0-9@.]/g, "").trim();
      setSearch({
       ...search,
       [name]: changedvalue,
      })
     }else if(name === "city"){
       const changedvalue = value.replace(/[^a-zA-Z0-9 ]/g, "").trim();
      setSearch({
       ...search,
       [name]: changedvalue,
      })
     }else{
     setSearch({
       ...search,
       [name]: value,
     });
    }
  };

  const handlePutRequestAccess = (
    row: any,
    actionType: string,
    checked: boolean
  ) => {

    fetchApiGetAllProjectTrades(row.projectId);
    dispatch(setProjectId(row.projectId));
  };

  const handleSubmitNotAddedTrades = (data : any) =>{
    setIsLoading(true);
    updateOrgTrades("", projectId, data)
      .then((resp) => {
        setIsOpenModal(false);
        dispatch(setProjectId(undefined));
        putPublicBidRequestAccess("", projectId, undefined)
        .then((resp) => {
          if (resp.status === "OK") {
            fetchGetBidsExpApi();
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
      })
  }

  const handleSearch = ()=>{
    console.log("search",search);
    getBidOppBySearch("", undefined, search)
    .then((resp)=>{
      if (resp.data === null || resp.data === new Map()) {
        setExploreOpportunitiesRowData([]);
      } else {
        setExploreOpportunitiesRowData(resp.data);
        setIsDueDate(false);
      }
    })

  }

  return (
    <>
      <div className="row gx-0">
        <div className="row mt-2">
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="projectName"
              name="projectName"
              type="text"
              className="form-control"
              label="Project Title"
              value={search.projectName}
              handleChange={handleChange}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="dueDateFrom"
              name="dueDateFrom"
              type="date"
              className="form-control"
              label="Bid Date (From)"
              shrink
              value={search.dueDateFrom}
              handleChange={handleChange}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="dueDateTo"
              name="dueDateTo"
              type="date"
              className="form-control"
              label="Bid Date (To)"
              shrink
              value={search.dueDateTo}
              handleChange={handleChange}
              required = {isDueDate}
            />
          </div>
          {/* <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="byGC"
              name="email"
              type="text"
              className="form-control"
              label="Posted By"
              value={search.email}
              handleChange={handleChange}
            />
          </div> */}
          <div className="col-2">
            <SelectInputField
              id="city"
              name="city"
              label="Select City"
              menuList={cityNames}
              value={search.city}
              menuClassName="d-block ms-1"
              handleChange={handleChange}
            />
          </div>
          <div className="col-2"></div>
          <div className="col-2 col-md-2 col-sm-2">
            <Button
              className="btn"
              style={{ background: color.mediumColor, color: "#fff" }}
              onClick={() => {
                setSearch(DefaultSearchFiltered);
                fetchGetBidsExpApi();
              }}
            >
              Clear
            </Button>
            <Button
              className="btn ms-2"
              style={{ background: color.mediumColor, color: "#fff" }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
        </div>
        <div className="row">
          <CustomTable
            headCells={headCells}
            rows={exploreOpportunitiesRowData}
            onSelectRowClick={handlePutRequestAccess}
            style={style}
            isLoading={isLoading}
          />
        </div>
      </div>
      <PubicBidsOppTradeModal
        openModal={isOpenModal}
        handleClose={() => setIsOpenModal(false)}
        handleSubmitNotAddedTrades={handleSubmitNotAddedTrades}
        allTradesRows={allTradesRows}
      />
      <CustomizedSnackbar
        openSnackBar={isOpen}
        message={message}
        handleCloseSnackBar={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default BidExploreOpportunities;
