import { FormikHelpers, FormikValues, useFormik } from "formik";
import * as Yup from "yup";

interface UseFormikValidationProps<T> {
  initialValues: T;
  validationSchema: Yup.Schema<T>;
  onSubmit?: (values: T, formikHelpers: FormikHelpers<T>) => void;
  validateOnMount?: boolean;
}

const UseFormikValidation = <T extends FormikValues>({
  initialValues,
  validationSchema,
  validateOnMount = false,
  onSubmit = (value: T) => {},
}: UseFormikValidationProps<T>) => {
  const formik = useFormik<T>({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnMount,
  });

  const handleReset = () => {
    formik.resetForm();
  };

  return {
    values: formik.values,
    errors: formik.errors,
    touched: formik.touched,
    handleChange: formik.handleChange,
    handleBlur: formik.handleBlur,
    handleSubmit: formik.handleSubmit,
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    setFieldValue: formik.setFieldValue,
    setFieldTouched: formik.setFieldTouched,
    handleReset,
  };
};

export default UseFormikValidation;
