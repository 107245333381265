import Modal from "react-modal";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/selectors/selectors";
import InputField from "../InputField";
import { InviteBidDetailsInfoType } from "../../utils/types";
import ReactQuill from "react-quill";
import { ProjectDocumentTable } from "../DataTable";
import { ModalStyle, SubmitProjectBidHeaders } from "../../utils/const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  subMailsList: string[];
  showAllMails?: boolean;
  handleUpdateNewMails?: (newMail: string) => void;
  handleClickOnPreview?: (viewMailPreview: boolean) => void;
  inviteBidDetailsInfo?: InviteBidDetailsInfoType;
}
const SubmitBidDetailsModal = ({
  isOpen = false,
  handleClose,
  inviteBidDetailsInfo
}: Props) => {

  const color = useSelector(selectThemeColor);

  return (
    <Modal
      id="addInviteSubContractor"
      isOpen={isOpen}
      style={ModalStyle}
      ariaHideApp={false}
    >
      <div className="modal-dialog  overflow-hidden">
        <div className="modal-content">
          <div className="modal-header border-bottom">
            <div className="col-8 opacity-70">
              <h4>Bid For {inviteBidDetailsInfo?.bidTradeCode}</h4>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <FontAwesomeIcon
                icon={faClose}
                className="pointer"
                size="xl"
                onClick={handleClose}
              />
            </div>
          </div>
          <div
            className="modal-body mt-3"
            style={{ height: "50vh", width: "50vw", overflowY:'auto' }}
          >
            <div className="row gx-0 mt-3">
              <InputField
                id="bidInviteEmail"
                name="bidInviteEmail"
                type="text"
                className="form-control"
                label="Bid Invite Email"
                value={inviteBidDetailsInfo?.bidInviteEmail}
                readonly
                shrink
              />
            </div>
            <div className="row gx-0 mt-3">
              <InputField
                id="bidAmount"
                name="bidAmount"
                type="text"
                className="form-control"
                label="Bid Amount"
                value={inviteBidDetailsInfo?.bidAmount}
                readonly
                shrink
              />
            </div>
            <div className="row gx-0 mt-2">
              <div className="col-12 px-0">
                <ReactQuill
                  theme="snow"
                  value={inviteBidDetailsInfo?.scope}
                  placeholder="Scope....."
                  readOnly
                />
              </div>
            </div>
            <div className="row gx-0 mt-2">
              <ProjectDocumentTable
                rows={inviteBidDetailsInfo?.attachedBidFiles}
                headers={SubmitProjectBidHeaders}
                isEdit={false}
                toShowPagination={false}
                maxHeight={150}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubmitBidDetailsModal;
