import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { setBasicFormDetails, setSelectedTradesDetails, setActiveStepAction, setDocumentDetails, setProjectId, setPostProjectRespStatus, setBidInvitationMailList, setProjectBidTradeId, setCustomTableId } from '../actions/projectFormActions';

const initialState: any = {
    projectBasicFormDetails: {
        projectOwnerName: "",
        projectName: "",
        unitNumber: "",
        budget: "",
        zipCode: "",
        addressTwo: "",
        addressOne: "",
        projectEstEmail: "",
        projectEstName: "",
        projectEstPhoneNo: "",
        state: "",
        city: "",
        constructionType: "",
        sector: "",
        requirements: [
            { id: 1, name: "DVBE Good Faith Efforts", checked: false },
            { id: 1, name: "Project Labor Agreement (PLA)", checked: false },
            { id: 1, name: "Community Workforce Agreement (CWA)", checked: false },
            {
                id: 1,
                name: "Skilled And Trained Workforce Requirements (STW)",
                checked: false,
            },
        ],
    },
    selectedFiles: [],
    selectedTrades: [],
    setActiveStep: 0,
    projectId:undefined,
    postProjectStatus:"Actively Bidding",
    bidInvitationMailList:[],
    projectBidTradeId: undefined,
    customTableId:undefined
};

export default createReducer<any, any>(initialState)
    .handleAction(setBasicFormDetails, (state: any, { payload }: any) => {
        return produce(state, (draft: any) => {
            draft.projectBasicFormDetails = payload;
        });
    })
    .handleAction(setDocumentDetails, (state: any, { payload }: any) => {
        return produce(state, (draft: any) => {
            draft.selectedFiles = payload;
        });
    })
    .handleAction(setSelectedTradesDetails, (state: any, { payload }: any) => {
        return produce(state, (draft: any) => {
            draft.selectedTrades = payload as any[];
        });
    })
    .handleAction(setActiveStepAction, (state: any, { payload }: any) => {
        return produce(state, (draft: any) => {
            draft.setActiveStep = payload;
        });
    })
     .handleAction(setProjectId, (state: any, { payload }: any) => {
        return produce(state, (draft: any) => {
            draft.projectId = payload;
        });
    })
    .handleAction(setPostProjectRespStatus, (state: any, { payload }: any) => {
        return produce(state, (draft: any) => {
            draft.postProjectStatus = payload;
        });
    })
    .handleAction(setBidInvitationMailList, (state: any, { payload }: any) => {
        return produce(state, (draft: any) => {
            draft.bidInvitationMailList = payload;
        });
    })
    .handleAction(setProjectBidTradeId, (state: any, { payload }: any) => {
        return produce(state, (draft: any) => {
            draft.projectBidTradeId = payload;
        });
    })
    .handleAction(setCustomTableId, (state: any, { payload }: any) => {
        return produce(state, (draft: any) => {
            draft.customTableId = payload;
        });
    });