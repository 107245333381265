import {useEffect, useState } from "react";
import Header from "./Header/header";
import SideBar from "./SideBar/sideBar";
import "./layout.css";
import ManageDivisions from "../ManageDivisions";
import ManageOrganizations from "../ManageOrganisations";
import ManageUser from "../ManageUser/manageUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import MyBidsInternal from "../MyBidsInternal";
import { useDispatch, useSelector } from "react-redux";
import { setColorTheme } from "../../redux/actions/actions";
import { selectThemeColor } from "../../redux/selectors/selectors";
import { LinearProgress } from "@mui/material";
import Loader from "../Loader";

const LayOut = () => {

  const [updateToggle, setUpdateToggle] = useState<boolean>(false);
  const [menuName, setMenuName] = useState<string>("");

  const [isBack, setIsBack] = useState(false);

  const [role, setRole] = useState<string>(localStorage.getItem('role') || "")
  const [isLoading, setIsLoading] =  useState<boolean>(true)

  const dispatch = useDispatch();
  const color = useSelector(selectThemeColor);

  const onClickToggle = () => {
    setUpdateToggle(!updateToggle);
  };

  const selectedMenu = (name: string) => {
    console.log('name', name)
    setMenuName(name);
  };

  const getClass = () => {
    return updateToggle ? "display-area-minimize" : "display-area-maximize";
  };

  useEffect(() => {
    dispatch(setColorTheme(role));
    return setRole(localStorage.getItem('role') || "")
  }, [role])

  // Check usage
  const isBackButton = (value: any) => {
    setIsBack(value);
    setSubMenu("");
  };

  const [subMenu, setSubMenu] = useState("");

  const getSubMenu = (menu :string) =>{
    setSubMenu(menu)
  }

  const renderMenu = (key: string) => {
    switch (key) {
      case "My Bids":
        return <MyBidsInternal setBackButton={setIsBack} isBack={isBack} getSubMenu={getSubMenu} submenu={subMenu}/>;
      case "Manage Trades":
        return <ManageDivisions />;
      case "Manage Organizations":
        return <ManageOrganizations setBackButton={setIsBack} isBack={isBack} getSubMenu={getSubMenu} />;
      case "Manage Users":
        return <ManageUser setBackButton={setIsBack} isBack={isBack} getSubMenu={getSubMenu} />;
      default:
        return <></>;
    }
  };
  
  const renderOrgMenu = (key: string) => {
    console.log('key', key)
    switch (key) {
      case "Manage Users":
        return <ManageUser setBackButton={setIsBack} isBack={isBack} getSubMenu={getSubMenu} />;
        case "Manage Trades":
        return <ManageDivisions />;
      case "Manage Organizations":
        return <ManageOrganizations setBackButton={setIsBack} isBack={isBack} getSubMenu={getSubMenu} />;
      default:
        return <MyBidsInternal setBackButton={setIsBack} isBack={isBack} getSubMenu={getSubMenu} submenu={subMenu}/>;
    }
  };

  useEffect(()=>{
    setIsBack(false)
    setSubMenu("")
  },[menuName]);

  useEffect(()=>{
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 0);
  },[]);

  return (
    <>
      {isLoading ? (
        <Loader className=""/>
      ) : (
        <div className="container-fluid g-0" style={{ overflow: "hidden" }}>
          <Header
            onClickToggle={onClickToggle}
            showMenuBar={role === null}
            onMenuClick={selectedMenu}
          />
          {role === null ? (
            <main style={{ overflow: "hidden" }}>
              <div className="d-flex">
                <SideBar
                  key={"sideBar"}
                  toggle={updateToggle}
                  onMenuClick={selectedMenu}
                />
                <div className={getClass() + "  display-area-maximize mt-3"}>
                  <div className="sub-header display-5 p-3 px-5">
                    <div className="d-flex border-bottom py-2 align-items-center ">
                      {isBack ? (
                        <>
                          <FontAwesomeIcon
                            className="mx-1 pointer size-xl"
                            icon={faArrowLeft}
                            size="2xs"
                            style={{ color: color.darkColor }}
                            onClick={() => {
                              setIsBack(false);
                              setSubMenu("");
                            }}
                          />
                          <h4 className="m-0 size-xl">
                            {menuName} {subMenu}
                          </h4>
                        </>
                      ) : (
                        <h4 className="m-0 size-xl">{menuName}</h4>
                      )}
                    </div>
                  </div>
                  <section className="container" style={{ height: "80vh", overflowY: "scroll" }}>
                    {renderMenu(menuName)}
                  </section>
                </div>
              </div>
            </main>
          ) : (
            <main className="mt-4">
            
              <div className="container-fluid" style={{height:"80vh"}}>
              <div className="row">
                { menuName !== 'My Bids' && <h5>{menuName}</h5>}
              </div>
                {renderOrgMenu(menuName)}
              </div>
            </main>
          )}
        </div>
      )}
    </>
  );
};
export default LayOut;