import { PostProjectEnum } from "../../utils/enum";

export const selectActiveStep = (state: any) => {
    return state.projectFormReducers.setActiveStep;
};

export const selectActiveStepOnboading = (state: any) => {
    return state.testReducer.setActiveStep;
};

export const selectThemeColor = (state: any) => {
    return state.testReducer.color;
};

export const selectProjectFormDetails = (state: any) => {
    return state.projectFormReducers.projectBasicFormDetails;
};

export const selectDocumentDetails = (state: any) => {
    return state.projectFormReducers.selectedFiles;
};

export const selectProjectId = (state: any) => {
    return state.projectFormReducers.projectId;
};

export const selectPostProjectStatus = (state: any) => {
    return state.projectFormReducers.postProjectStatus;
};

export const selectIsPostProjectButtonVisible = (state: any) => {
  const status = state.projectFormReducers.postProjectStatus;
  if (status === PostProjectEnum.CLOSED) {
    return false;
  } else if (status === PostProjectEnum.UNDER_CONSTRUCTION) {
    return false;
  }
  return true;
};

export const selectBidInvitationMailList = (state: any) => {
    return state.projectFormReducers.bidInvitationMailList;
};

export const selectprojectBidTradeId = (state: any) => {
    return state.projectFormReducers.projectBidTradeId;
};

export const selectTradeDetails = (state: any) => {
    return state.projectFormReducers.selectedTrades;
};

export const withModalDialogActions = (state:any)=>{
    return state.projectFormReducers
}

export const selectCustomTableId = (state: any) => {
    return state.projectFormReducers.customTableId;
};
