import * as Yup from "yup";
import { useFormik } from "formik";

const Utils = () => {
  const initialValues = {
    newPassword: "",
    confirmNewPassword: "",
  };
  const ValidationSchema = Yup.object({
    newPassword: Yup.string()
      .required("Required!")
      .min(
        8,
        "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special eg. Unnipatt@123"
      )
      .matches(/[a-z]+/, "Atleast One lowercase character")
      .matches(/[A-Z]+/, "Atleast One uppercase character")
      .matches(/[@$!%*#?&]+/, "Atleast One special character")
      .matches(/\d+/, "Atleast One number")
      .matches(/^\S*$/, "White Spaces are not allowed"),
    confirmNewPassword: Yup.string()
      .required("Required!")
      .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  });

  const onSubmit = async () => {};

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
    isValid,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit,
    validateOnMount: true,
  });

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
    isValid,
    onSubmit,
    setErrors,
  };
};

export default Utils;
