import * as Yup from "yup";
import { useFormik } from "formik";
import { ProjectBidForm } from "../../../utils/types";
import { useState } from "react";

export const DefaultFormData: ProjectBidForm = {
  projectName: "",
  projectNumber: "",
  status: "",
  projectContact: {id:null, firstName:''},
  buildingSize: "",
  siteSize: "",
  buildingCount: 0,
  storyCount: 0,
  dollarSize: "",
  duration: "",
  onlineAccess: "",
  buildingType: "",
  bidType: "",
  jobWalkDate: "",
  jobWalkTime: "",
  bidDate: "",
  bidTime: "",
  addressTwo: "",
  addressOne: "",
  state: "",
  zip: "",
  city: "",
  constructionType: "",
  budget: "",
  sector: "",
  scope: "",
};

interface Props {
  props: any;
  bidSpecification: any;
  projectFormData: any;
  projectDocuments: any;
  tradeList: any;
}

const Utils = () => {
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const InitialValues: ProjectBidForm = DefaultFormData;

  const ValidationSchema = Yup.object({
    projectName: Yup.string().trim().required("Required!"),
    projectNumber: Yup.number().positive("Positive!"),
    buildingCount: Yup.number().positive("Positive!"),
    storyCount: Yup.number().positive("Positive!"),
    state: Yup.string().required("Required!"),
    zip: Yup.string().required("Required!")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
    city: Yup.string().trim().required("Required!"),
    bidDate: Yup.string().trim().required("Required!"),
    bidTime: Yup.string().trim().required("Required!"),

  });

  const onSubmit = () => { }

  const { values, errors, handleChange, handleSubmit, handleReset, setFieldValue, setFieldError, touched, setTouched, setFieldTouched, isValid,resetForm } = useFormik({
    initialValues: { ...InitialValues },
    validationSchema: ValidationSchema,
    validateOnMount: true,
    onSubmit,
  })

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
    isSubmit,
    isValid,
    resetForm
  }
};

export default Utils;
