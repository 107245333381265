import Tooltip from "@mui/material/Tooltip";

interface Props {
  children?: React.ReactNode;
  title: string;
}

const CustomTooltipOverlay: React.FC<Props> = ({ children, title }: Props) => {
  return (
    <Tooltip title={title} arrow>
      <span>{children ? children : <></>}</span>
    </Tooltip>
  );
};

export default CustomTooltipOverlay;
