import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Paper } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/selectors/selectors";
import { useDispatch } from "react-redux";
import { setBidInvitationMailList } from "../../redux/actions/projectFormActions";
import CustomTablePagination from "./customTablePagination";
import RowDataTable from "./rowDataTable";
import { StyledTableCell } from "../../customeTheme";

interface Props {
  headCells: any[];
  rows: any[];
  style?: any;
  maxHeight?: any;
  onSelectRowClick?: (row: any, actionType: string, checked: boolean) => void;
  handleCheck?: (row: any, checked: boolean) => void;
  onClickGetCell?: (cell: any) => void;
  onDeleteRowClick?: (index: any) => void;
  onViewRow?: (index: any) => void;
  handleCheckAll?: (rows: any) => void;
  handleChangeDropDownStatus?: (id: number, status: string) => void;
  handelRemoveTrade?: (id:number, actionTaken: string) => void;
  toShowPagination?:boolean;
  isLoading?:boolean;
}

const CustomTable = ({
  headCells,
  rows,
  onSelectRowClick,
  onClickGetCell = () => {
    return;
  },
  onDeleteRowClick = () => null,
  onViewRow,
  style,
  maxHeight= 440,
  handleCheck,
  handleCheckAll,
  handleChangeDropDownStatus,
  handelRemoveTrade,
  toShowPagination=true,
  isLoading=false
}: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>("");
  const [subContractorMails, setSubContractorMails] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [selectTradeId, setSelectTradeId] = useState<any[]>([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const color = useSelector(selectThemeColor);

  const dispatch = useDispatch();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);    console.log('ev', event.target.value)
    setPage(0);
  };

  const handleStatusChange = (id: any, status: any) => {
    handleChangeDropDownStatus!(id, status);
  };

  const handleSetsubContractorMails = (bidInvitationMailList: string[]) => {
    setBidInvitationMailList(bidInvitationMailList);
  };

  const handleSelectTradeId = (tradeId : any) =>{
    setSelectTradeId(tradeId);
  }

  return (
    <>
      <Paper className="shadow p-0" sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer key="table-container" sx={{ maxHeight: {maxHeight} }}>
          <Table
            key="table"
            className=""
            size="small"
            aria-label="sticky table"
            stickyHeader={true}
          >
            <TableHead key="table-head" style={{ maxHeight: "300px" }}>
              <TableRow key="row" style={{ zIndex: 1 }}>
                {headCells.map((cell) => (
                  <StyledTableCell
                    key={cell.id}
                    style={{
                      width: 300,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {<span>{cell.name}</span>}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              key="body"
              style={{
                tableLayout: "fixed",
              }}
            >
              {rows.length > 0 ? (
                (rowsPerPage > 0 && toShowPagination
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row, rowIndex) => (
                  <RowDataTable
                    key={rowIndex}
                    headCells={headCells}
                    row={row}
                    rowIndex={rowIndex}
                    handleSetsubContractorMails={handleSetsubContractorMails}
                    handleSelectTradeId={handleSelectTradeId}
                    subContractorMails={subContractorMails}
                    selectTradeId={selectTradeId}
                    onSelectRowClick={onSelectRowClick}
                    handleCheck={handleCheck}
                    onClickGetCell={onClickGetCell}
                    handelRemoveTrade={handelRemoveTrade}
                    onDeleteRowClick={onDeleteRowClick}
                    onViewRow={onViewRow}
                    handleChangeDropDownStatus={handleChangeDropDownStatus}
                    isLoading={isLoading}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    align="center"
                    style={{ textAlign: "center" }}
                  >
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {toShowPagination && rows.length !== 0 && (
          <CustomTablePagination
            headCells={headCells}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </>
  );
};

export default CustomTable;
