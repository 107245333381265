import { faBars, faCircleUser, faEnvelope, faHome, faRemove, faUserCog, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Avatar, Badge, Box, ListItemIcon,Button, Menu, MenuItem } from "@mui/material";
import { Logout, Money } from "@mui/icons-material";
import { ChatMessageBox } from "../../ChatBox";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../../redux/selectors/selectors";
import { setColorTheme } from "../../../redux/actions/actions";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import ProfileDetail from "../profileDetail";
import { ModalStyle } from "../../../utils/const";


const Header = (props: any) => {
  const color = useSelector(selectThemeColor);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onNewCLickToggle = (event : any) => {
    if(props.showMenuBar){
      props.onClickToggle();
    }else{
      setAnchorElMenuBar(event.currentTarget);
    }
  }

  const [isOpenChatMessage, setIsOpenChatMessage] = useState(false);
  const [onClickNameSelect, setOnClickNameSelect] = useState<string>("Default");
  const [onClickMenuSelect, setOnClickMenuSelect] = useState<
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined
  >("My Bids");
  const [modalIsOpen, setIsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMenuBar, setAnchorElMenuBar] = useState(null);

  const [messageAnchorEl, setMessageAnchorEl] = useState<SVGSVGElement | null>(null);
  const openMessageAnchorEl = Boolean(messageAnchorEl);
  const open = Boolean(anchorEl);
  const openMenuBar = Boolean(anchorElMenuBar);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElMenuBar(null);
  };
  
  const stringToColor = (colorName: string) => {
    let hashCode = 0;
    let count;

    for (count = 0; count < colorName.length; count += 1) {
      hashCode = colorName.charCodeAt(count) + ((hashCode << 5) - hashCode);
    }

    let color = '#';
    for (count = 0; count < 3; count += 1) {
      const value = (hashCode >> (count * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  const stringAvatar = (name: string) => {
    if (!name) {
      return {
        sx: {
          bgcolor: color.lightColor,
          fontSize: "16px",
          width: 30,
          height: 30,
          marginRight: 0
        },
        children: `NA`
      };
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: "16px",
        width: 30,
        height: 30,
        marginRight: 0
      },
      children: `${name[0][0]}`
    };
  };

  const getLogoUrl = () => {
    // const role = localStorage.getItem('role');
    // switch (role) {
    //   case 'Organization Admin':
    //     return 'BW logo-blue bg-svg.svg';

    //   case 'System Admin':
    //     return 'BW logo-transparent bg.png'

    //   default:
    //     break;
    // }
    return 'BW logo-blue bg-svg.svg';
  }
  
  const getMenuItems = () => {
    const storedMenus = localStorage.getItem('menus');
    if (storedMenus) {
      return JSON.parse(storedMenus);
    }
    return [];
  };

  const handleMenuBarClose = () => {
    setAnchorElMenuBar(null);
  };
  
  const menuItems = getMenuItems();

  return (
    <>
      <nav
        className="navabr navbar-expand px-2"
        style={{
          background: props.showMenuBar ? color.mediumColor : color.darkColor,
        }}
      >
        <div className="row gx-0 gy-0">
          <div className="side-nav-button col-3 col-md-3 col-sm-2 p-0 text-light">
            <div className="row">
              <FontAwesomeIcon
                className="pointer col-1 mt-3 ms-4"
                icon={faBars}
                size="2x"
                style={{ color: color.light }}
                onClick={(e) => onNewCLickToggle(e)}
              />
              <div className="col-3 col-md-3 col-sm-3 mt-2">
                <img
                  src={getLogoUrl()}
                  width="310px"
                  height="56px"
                  alt="Login page"
                  onError={(e) => {
                    const img = e.target as HTMLImageElement;
                    img.src = "image-not-found.png";
                  }}
                  style={{
                    cursor: "default",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-6 col-md-6 col-sm-6 d-flex justify-content-end mt-2"></div>
          <div className="col-3 col-md-3 col-sm-3 mt-2 d-flex justify-content-end">
            {false && (
              <Badge
                className="mt-2 me-3 pointer"
                color="info"
                badgeContent={5}
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="2x"
                  pull="left"
                  aria-controls={openMessageAnchorEl ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMessageAnchorEl ? "true" : undefined}
                  onClick={(
                    event: React.MouseEvent<SVGSVGElement, MouseEvent>
                  ) => {
                    if (event.currentTarget) {
                      setMessageAnchorEl(event.currentTarget);
                    }
                  }}
                />
              </Badge>
            )}
            <Box
              className="d-flex p-0 px-2"
              style={{ color: "black", maxHeight: 40, borderRadius: "20px" }}
              id="button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <h5
                className=""
                style={{
                  textTransform: "capitalize",
                  color: props.showMenuBar ? "dark" : color.lightColor,
                }}
              >
                <span className="d-block size-md text-center">
                  {localStorage.getItem("orgName")}
                </span>
                <span className="d-block size-sm">
                  {localStorage.getItem("name")}
                </span>
              </h5>
              <FontAwesomeIcon
                className="pointer"
                icon={faCircleUser}
                size="2x"
                pull="left"
                style={{
                  paddingLeft: 5,
                  color: props.showMenuBar ? "dark" : color.lightColor,
                }}
                onClick={handleClick}
              />
            </Box>
            <Menu
              id="basic-menu"
              className="d-block mt-1 shadow"
              anchorEl={messageAnchorEl}
              open={openMessageAnchorEl}
              onClose={() => setMessageAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                className="bg-light border-bottom opacity-50 d-flex justify-content-center"
                onClick={() => {
                  setMessageAnchorEl(null);
                }}
                style={{ width: "25vw" }}
              >
                <span className="border btn">Today</span>
              </MenuItem>
              <MenuItem
                className="bg-light border-bottom"
                onClick={() => {
                  setIsOpenChatMessage(true);
                  setOnClickNameSelect("Robert");
                  setMessageAnchorEl(null);
                }}
              >
                <div className="row d-grid">
                  <div className="d-flex">
                    <div className="col-1 g-0">
                      <Avatar {...stringAvatar("Robert")} />
                    </div>
                    <span className="col-9 mt-1">
                      <b className="ms-1 me-2">Robert :</b> Hello, Need Help
                      regarding....
                    </span>
                    <span className="col-2 d-flex justify-content-end mt-1 fw-bolder">
                      {new Date().getHours() + ": " + new Date().getMinutes()}
                    </span>
                  </div>
                </div>
              </MenuItem>
              <MenuItem
                className="bg-light  border-bottom"
                onClick={() => {
                  setIsOpenChatMessage(true);
                  setOnClickNameSelect("Jon");
                  setMessageAnchorEl(null);
                }}
              >
                <div className="row d-grid">
                  <div className="d-flex">
                    <div className="col-1 g-0">
                      <Avatar {...stringAvatar("Jon")} />
                    </div>
                    <span className="col-9 mt-1 text-word-wrap">
                      <b className="ms-1 me-2">Jon :</b>
                      <span
                        className="text-wrap"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Hello, Need Help regarding...
                      </span>
                    </span>
                    <span className="col-2 d-flex justify-content-end mt-1 fw-bolder">
                      {new Date().getHours() + ": " + new Date().getMinutes()}
                    </span>
                  </div>
                </div>
              </MenuItem>
              <MenuItem
                className="bg-light border-bottom opacity-50 d-flex justify-content-center"
                onClick={() => {
                  setMessageAnchorEl(null);
                }}
              >
                <span className="border btn">Yesterday</span>
              </MenuItem>
              <MenuItem
                className="bg-light  border-bottom"
                onClick={() => {
                  setIsOpenChatMessage(true);
                  setOnClickNameSelect("Sam");
                  setMessageAnchorEl(null);
                }}
              >
                <div className="row d-grid">
                  <div className="d-flex">
                    <div className="col-1 g-0">
                      <Avatar {...stringAvatar("Sam")} />
                    </div>
                    <span className="col-9 mt-1">
                      <b className="col-2 ms-1 me-2">Sam :</b> Hello, Need Help
                      regarding....
                    </span>
                    <span className="col-2 d-flex justify-content-end mt-1 fw-bolder">
                      {new Date().getHours() + ": " + new Date().getMinutes()}
                    </span>
                  </div>
                </div>
              </MenuItem>
              <MenuItem
                className="bg-light  border-bottom"
                onClick={() => {
                  setIsOpenChatMessage(true);
                  setOnClickNameSelect("Roy");
                  setMessageAnchorEl(null);
                }}
              >
                <div className="row d-grid">
                  <div className="d-flex">
                    <div className="col-1 g-0">
                      <Avatar {...stringAvatar("Roy")} />
                    </div>
                    <span className="col-9 mt-1">
                      <b className="ms-1 me-2">Roy :</b> Hello, Need Help
                      regarding....
                    </span>
                    <span className="col-2 d-flex justify-content-end mt-1 fw-bolder">
                      {new Date().getHours() -
                        1 +
                        ": " +
                        new Date().getMinutes()}
                    </span>
                  </div>
                </div>
              </MenuItem>
              <MenuItem
                className="bg-light"
                onClick={() => {
                  setIsOpenChatMessage(true);
                  setOnClickNameSelect("Jon");
                  setMessageAnchorEl(null);
                }}
              >
                <div className="row d-grid">
                  <div className="d-flex">
                    <div className="col-1 g-0">
                      <Avatar {...stringAvatar("Harry")} />
                    </div>
                    <span className="col-9 mt-1">
                      <b className="ms-1 me-2">Harry :</b> Hello, Need Help
                      regarding....
                    </span>
                    <span className="col-2 d-flex justify-content-end mt-1 fw-bolder">
                      {new Date().getHours() -
                        2 +
                        ": " +
                        new Date().getMinutes()}
                    </span>
                  </div>
                </div>
              </MenuItem>
            </Menu>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                className="d-flex align-items-center p-2"
                onClick={() => {
                  setIsOpen(true);
                  handleClose();
                }}
              >
                <Avatar
                  sx={{
                    fontSize: "15px",
                    width: 20,
                    height: 20,
                    marginRight: 1,
                    background: color.darkColor,
                  }}
                />
                Profile
              </MenuItem>
              <MenuItem
                className="d-flex align-items-center p-2"
                onClick={() => {
                  navigate("/login");
                  localStorage.clear();
                  dispatch(setColorTheme(" "));
                }}
              >
                <ListItemIcon style={{ minWidth: "28px" }}>
                  <Logout fontSize="small" style={{ color: color.darkColor }} />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
            <Menu
              id="basic-menu"
              anchorEl={anchorElMenuBar}
              open={openMenuBar}
              onClose={handleMenuBarClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {menuItems.map(
                (menu: {
                  id: Key | null | undefined;
                  name:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | Iterable<ReactNode>
                    | ReactPortal
                    | null
                    | undefined;
                }) => (
                  <MenuItem
                    key={menu.id}
                    className="d-block p-2"
                    onClick={() => {
                      props.onMenuClick(menu.name);
                      setOnClickMenuSelect(menu.name);
                      setAnchorElMenuBar(null);
                    }}
                    style={{
                      opacity: onClickMenuSelect === menu.name ? 1 : 0.7,
                    }}
                  >
                    {menu.name === "Manage Users" ? (
                      <FontAwesomeIcon
                        icon={faUserCog}
                        style={{
                          fontSize: "24px",
                          width: 20,
                          height: 20,
                          marginRight: "8px",
                          color: color.darkColor,
                        }}
                      />
                    ) : menu.name === "Manage Trades" ? (
                      <FontAwesomeIcon
                        icon={faUserShield}
                        style={{
                          fontSize: "24px",
                          width: 20,
                          height: 20,
                          marginRight: "8px",
                          color: color.darkColor,
                        }}
                      />
                    ) : menu.name === "Manage Organizations" ? (
                      <FontAwesomeIcon
                        icon={faHome}
                        style={{
                          fontSize: "24px",
                          width: 20,
                          height: 20,
                          marginRight: "8px",
                          color: color.darkColor,
                        }}
                      />
                    ) : menu.name === "My Bids" ? (
                      <Money
                        sx={{
                          fontSize: "24px",
                          width: 20,
                          height: 20,
                          marginRight: "8px",
                          color: color.darkColor,
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {menu.name}
                  </MenuItem>
                )
              )}
            </Menu>
          </div>
        </div>
      </nav>

      <Modal
        id="viewDocument"
        isOpen={modalIsOpen}
        style={ModalStyle}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content justify-content-center">
            <div className="modal-header mb-2 mt-2">
              <div className="mx-auto opacity-70 fs-3">Profile Detail</div>
              <FontAwesomeIcon
                className="pointer"
                icon={faRemove}
                size="xl"
                onClick={() => setIsOpen(false)}
              />
            </div>
            <div
              className="modal-body mt-4"
              style={{ maxWidth: "750px", maxHeight: "700px" }}
            >
              <div className="row">
                <ProfileDetail />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {isOpenChatMessage && (
        <ChatMessageBox
          receiverName={onClickNameSelect}
          handleClose={() => {
            setIsOpenChatMessage(false);
          }}
          sender={""}
        />
      )}
    </>
  );
}

export default Header;