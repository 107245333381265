import { CircularProgress } from "@mui/material";

interface Props{
    className:string;
    isButtonLoader?:boolean;
    label?:string;
}

const  Loader = ({className,isButtonLoader,label='Loading'} : Props) => {
  return (
    <div className={className}>
      <div className="d-block">
        <CircularProgress className="ms-4" color="secondary" variant="indeterminate" thickness={3} />
      { !isButtonLoader && <span className="d-flex">{label}...</span>}
      </div>
    </div>
  );
}

export default Loader