import { useState } from "react";
import MyBidsTable from "./MyBidsTable/myBidsTable";
import { Button } from "@material-ui/core";
import BidExploreOpportunities from "./BidExploreOpportunities/bidExploreOpportunities";
import { SubmenuEnum } from "../../utils/enum";
import { useDispatch } from "react-redux";
import {
  setPostProjectRespStatus,
  setProjectId,
} from "../../redux/actions/projectFormActions";
import ProjectBids from "./ProjectBids/ProjectBids";
import InvitedBidDetailForm from "./InvitedBidDetailForm/invitedBidDetailForm";

const MyBidsInternal = (props: any) => {
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [activeMenu, setActiveMenu] = useState(SubmenuEnum.MY_BIDS);
  const [bidId, setBidId] = useState(0);

  const dispatch = useDispatch();

  const handleSelectRow = (row: any) => {
    console.log('row', row)
    if (row.bidId) {
      setBidId(row.bidId);
    }
    setSelectedRow(row);
    dispatch(setProjectId(row.projectId));
  };

  const handleMyBids = () => {
    props.getSubMenu(SubmenuEnum.MY_BIDS);
    setActiveMenu(SubmenuEnum.MY_BIDS);
  };

  const handlePostBids = () => {
    setActiveMenu(SubmenuEnum.POST_BIDS);
    setSelectedRow([]);
    dispatch(setProjectId(undefined));
    dispatch(setPostProjectRespStatus(""));
  };

  const handleBidOpportClick = () => {
    props.getSubMenu(SubmenuEnum.BID_OPPORTUNITIES_NETWORK);
    setActiveMenu(SubmenuEnum.BID_OPPORTUNITIES_NETWORK);
  };

  const handleOnSelectCell = (cell: any) => {
    if (cell.length === 0) {
      setActiveMenu(SubmenuEnum.EDIT_PROJECT);
    } else {
      setActiveMenu(SubmenuEnum.INVITED_BIDS);
    }
  };

  const renderSubMenu = () => {
    switch (activeMenu) {
      case SubmenuEnum.BID_OPPORTUNITIES_NETWORK:
        return <BidExploreOpportunities />;
      case SubmenuEnum.MY_BIDS:
        return (
          <MyBidsTable
            onSelectProjectRow={handleSelectRow}
            bidId={bidId}
            onSelectCell={handleOnSelectCell}
          />
        );
      case SubmenuEnum.EDIT_PROJECT:
      case SubmenuEnum.POST_BIDS:
        return (
          <ProjectBids
            projectName={selectedRow.projectName}
            setBackButton={() => setActiveMenu(SubmenuEnum.MY_BIDS)}
          />
        );
      case SubmenuEnum.INVITED_BIDS:
        return (
          <InvitedBidDetailForm
            status={selectedRow.status}
            setBackButton={() => setActiveMenu(SubmenuEnum.MY_BIDS)}
            bidId={bidId}
          />
        );
      default:
        return (
          <MyBidsTable
            onSelectProjectRow={handleSelectRow}
            bidId={bidId}
            onSelectCell={handleOnSelectCell}
          />
        );
    }
  };

  return (
    <>
      <div className="row gx-0 mt-2 mb-2">
        <Button
          className="btn main-btn col-2 offset-2  mb-1"
          style={{
            opacity:
              activeMenu === SubmenuEnum.MY_BIDS ||
              activeMenu === SubmenuEnum.EDIT_PROJECT ||
              activeMenu === SubmenuEnum.INVITED_BIDS
                ? 1
                : 0.7,
          }}
          onClick={handleMyBids}
        >
          My Bids
        </Button>
        <Button
          className="btn main-btn col-2 offset-2 ms-4 mb-1"
          style={{
            opacity:
              activeMenu === SubmenuEnum.BID_OPPORTUNITIES_NETWORK ? 1 : 0.7,
          }}
          onClick={handleBidOpportClick}
        >
          Bid Opportunities Network
        </Button>
        <Button
          className="btn main-btn col-1 ms-4 mb-1"
          style={{
            opacity: activeMenu === SubmenuEnum.POST_BIDS ? 1 : 0.7,
          }}
          onClick={handlePostBids}
        >
          Post Bid
        </Button>
      </div>
      <div
        className="overflow-hidden w-100"
        style={{
          height: activeMenu === SubmenuEnum.POST_BIDS ? "87vh" : " 80vh",
        }}
      >
        {renderSubMenu()}
      </div>
    </>
  );
};

export default MyBidsInternal;
