import InputField from "../../InputField";
import { useContext } from "react";
import { GET_USA_STATE_AND_CITY } from "../../../utils/api";

const BasicDetails = ({ formikProps }: any) => {
  const { values, handleChange, errors ,setFieldTouched,setTouched, touched,setFieldValue,setFieldError } = formikProps;

  const fetchLocation = (zip: string) => {
    return new Promise<void>((resolve, reject) => {
      try {
        if (zip.length === 5) {
          fetch(GET_USA_STATE_AND_CITY + zip)
            .then((response) => {
              if (!response.ok) {
                console.log("Errr");
                throw new Error("Invalid Zip Code");
              }
              return response.json();
            })
            .then((json) => {
              setFieldValue("state", json["places"][0]["state abbreviation"]);
              setFieldValue("city", json["places"][0]["place name"]);
              setTimeout(() => {
                setTouched({ state: false });
              }, 0);
              resolve();
            })
            .catch((error: Error) => {
              setTouched({ zip: true });
              setTimeout(() => {
                setFieldError("zip", error.message);
              }, 0);
              setFieldValue("state", "");
              setFieldValue("city", "");
              reject(error);
            });
        } else {
          throw new Error("Must be exactly 5 digits");
        }
      } catch (error) {
        setFieldValue("state", "");
        setFieldValue("city", "");
        setTimeout(() => {
          setFieldError("zipCode", "Must be exactly 5 digits");
        }, 0);
        reject(error);
      }
    });
  };

  const handleFetchLocation = async (zip: string) => {
    try {
      await fetchLocation(zip);
    } catch (error) {
      console.error('Error fetching location:', error);
    }
  };

  return (
    <>
      <div className="row justify-content-center gap-5 mt-3">
        <div className="col-8 col-md-8 col-sm-8">
          <InputField
            id="orgName"
            name="orgName"
            maxLength={100}
            className="form-control"
            type="text"
            value={values.orgName.replace(/[^a-zA-Z0-9 ]/g, '')}
            required
            handleChange={handleChange}
            handleBlur={() => setFieldTouched("orgName", true) }
            label="Company Name"
            error={touched.orgName && errors.orgName}
          />
        </div>
      </div>
      <div className="row justify-content-center mt-3">
      <div className="col-8 col-md-8 col-sm-8">
          <InputField
            id="name"
            name="name"
            maxLength={50}
            value={values.name.replace(/[^a-zA-Z0-9 ]/g, '')}
            className="form-control"
            type="text"
            required
            handleChange={handleChange}
            handleBlur={() => setFieldTouched("name", true) }
            label="Name"
            error={touched.name && errors.name}
          />
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-4 col-md-4 col-sm-4">
          <InputField
            id="phoneNumber"
            name="phoneNumber"
            value={values.phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
            maxLength={10}
            className="form-control"
            type="text"
            required
            handleChange={handleChange}
            handleBlur={() => setFieldTouched("phoneNumber", true) }
            label="Mobile No."
            error={touched.phoneNumber && errors.phoneNumber}
          />
        </div>
        <div className="col-4 col-md-4 col-sm-4">
          <InputField
            id="email"
            name="email"
            value={values.email}
            className="form-control"
            type="email"
            required
            handleChange={handleChange}
            handleBlur={() => setFieldTouched("email", true) }
            label="Email"
            error={touched.email && errors.email}
            maxLength={50}
          />
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-4 col-md-4 col-sm-4">
          <InputField
            id="password"
            name="password"
            type="password"
            className="form-control"
            value={values.password}
            label="Password"
            handleChange={handleChange}
            handleBlur={()=> setFieldTouched("password", true)}
            error={touched.password && errors.password}
            autoComplete="new-password"
            required
          />
        </div>
        <div className="col-4 col-md-4 col-sm-4">
          <InputField
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            className="form-control"
            value={values.confirmPassword}
            label="Confirm Password"
            handleChange={handleChange}
            handleBlur={()=> setFieldTouched("confirmPassword", true)}
            error={touched.confirmPassword && errors.confirmPassword}
            autoComplete="new-password"
            required
          />
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-8 col-md-8 col-sm-8">
          <InputField
            id="addressOne"
            name="addressOne"
            type="addressOne"
            className="form-control"
            value={values.addressOne.replace(/[^a-zA-Z0-9]/g, '')}
            label="Address 1"
            handleChange={handleChange}
            error={errors.addressOne}
          />
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-8 col-md-8 col-sm-8">
          <InputField
            id="addressTwo"
            name="addressTwo"
            type="addressTwo"
            className="form-control"
            value={values.addressTwo.replace(/[^a-zA-Z0-9 ]/g, '')}
            label="Address 2"
            handleChange={handleChange}
          />
        </div>
      </div>

      <div className="row justify-content-center mt-3">
        <div className="col-3 col-md-3 col-sm-3">
          <InputField
            id="zip"
            name="zip"
            type="text"
            className="form-control"
            value={values.zip.replace(/\D/g, '')}
            label="Enter Zip Code"
            maxLength={5}
            handleChange={(e: any) => {
              handleChange(e);
              handleFetchLocation(e.target.value);
            }}
            error={touched.zip && errors.zip}
            required
          />
        </div>
        <div className="col-2 col-md-2 col-sm-2">
          <InputField
            id="state"
            name="state"
            type="text"
            className="form-control"
            value={values.state.replace(/[^a-zA-Z!@# ]/g, '').toUpperCase()}
            label="Enter State"
            handleChange={handleChange}
            error={touched.state && errors.state}
            maxLength={2}
            required
          />
        </div>
        <div className="col-3 col-md-3 col-sm-3">
          <InputField
            id="city"
            name="city"
            type="text"
            className="form-control"
            value={values.city.replace(/[^a-zA-Z!@# ]/g, '')}
            label="Enter City"
            handleChange={handleChange}
            error={touched.city && errors.city}
            required
          />
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-4 col-md-4 col-sm-4">
          <InputField
            id="taxNo"
            name="taxNo"
            type="taxNo"
            className="form-control"
            maxLength={50}
            value={values.taxNo.replace(/[^a-zA-Z0-9 ]/g, '')}
            label="Tax Id"
            handleChange={handleChange}
            error={touched.taxNo && errors.taxNo}
          />
        </div>
        <div className="col-4 col-md-4 col-sm-4">
          <InputField
            id="licenseNo"
            name="licenseNo"
            type="licenseNo"
            className="form-control"
            maxLength={50}
            value={values.licenseNo.replace(/[^0-9]/g, '')}
            label="License Number"
            handleChange={handleChange}
            handleBlur={()=> null}
            error={touched.licenseNo && errors.licenseNo}
          />
        </div>
      </div>
    </>
  );
};

export default BasicDetails;
