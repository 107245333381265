import React, { useEffect, useState } from "react";
import { verifyToken } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const VerifyEmail = () => {
  const [status, setStatus] = useState("Verifying...");
  const [error, setError] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const url = new URL(`http://example.com${hash}`);
    const query = new URLSearchParams(url.search);
    const token = query.get("token");

    if (token) {
      verifyToken("", token, undefined)
        .then((response: { status: string; message: any }) => {
          if (response.status === "OK") {
            setStatus("Email verified successfully!");
            setShowButton(true);
          } else {
            setStatus("Verification failed.");
            setError(response.message || "An error occurred");
          }
        })
        .catch((error: { message: any }) => {
          setStatus("Verification failed.");
          setError(error.message || "An error occurred");
        });
    } else {
      setStatus("Invalid verification link.");
    }
  }, []);

  const handleButtonClick = () => {
    navigate("/login");
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h2>{status}</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}

      {showButton && (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            style={{ maxWidth: "200px" }}
          >
            Back to Sign In
          </Button>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
