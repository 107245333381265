import Modal from "react-modal";
import { ModalStyle } from "../../utils/const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button, CircularProgress } from "@mui/material";

interface Props {
  isOpen: boolean;
  title?: string;
  message: string;
  handleClose: () => void;
  handleConfirm: () => void;
  handleUpdateNewMails?: (newMail: string) => void;
  isLoading?: boolean;
}

const ActionConfirmModal = ({
  isOpen,
  handleClose,
  title = "",
  message = "",
  handleConfirm,
  isLoading = false,
}: Props) => {
  return (
    <Modal id="confirm" isOpen={isOpen} style={ModalStyle} ariaHideApp={false}>
      <div className="modal-header border-bottom">
        <h5 className="modal-title">{title}</h5>
        <div className="d-flex justify-content-end">
          <FontAwesomeIcon
            className="btn-close pointer"
            icon={faClose}
            size="lg"
            onClick={() => {
              handleClose();
            }}
          />
        </div>
      </div>
      <div
        className="modal-body"
        style={{
          width: "60vh",
          height: "30vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <p
          id="modal-description"
          className="modal-text size-lg"
          style={{ lineHeight: "1.5", marginTop: "10%" }}
        >
          {message}
        </p>
      </div>

      <div className="footer d-flex justify-content-between align-items-center p-0 border-top mt-1">
        <Button
          className="btn col-5 mt-1"
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn col-5 mt-1"
          onClick={(e) => {
            e.preventDefault();
            handleConfirm();
          }}
        >
          {isLoading ? (
            <CircularProgress
              className="ms-1"
              color="info"
              variant="indeterminate"
              thickness={2}
              size={26}
            />
          ) : (
            "Confirm"
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default ActionConfirmModal;
