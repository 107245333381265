import { useCallback } from "react";

const UseCommonTreeFunction = () => {
  const getNthKeyNodesResp = useCallback(
    (key: number, obj: any[]) => getNthKeyNodes(key, obj),
    []
  );
  const mergeSelectedNodesResp = useCallback(
    (selectedNodeKey: any, selectedNode: any, result: any[]) =>
      mergeSelectedNodes(selectedNodeKey, selectedNode, result),
    []
  );
  const getSelectedResultNodeResp = useCallback(
    (selectedFullNode: any[], selectedKey: any) =>
    getSelectedResultNode(selectedFullNode, selectedKey),
    []
  );
  const sortChildrenItemsById = (data: any[]) => {
    return data.map(item => ({
        ...item,
        children: item.children.sort((a: { id: number; }, b: { id: number; }) => a.id - b.id)
    }));
};
  return {
    getNthKeyNodesResp,
    mergeSelectedNodesResp,
    sortChildrenItemsById,
    getSelectedResultNodeResp
  };
};

export default UseCommonTreeFunction;

const getNthKeyNodes = (nodeKey: number, nodeObj: any[]): any => {
  let currentNode = nodeObj[0];
  try {
    for (let i = 0; i < nodeObj.length; i++) {
      currentNode = nodeObj[i];
      if (nodeObj[i].id === nodeKey) {
        return currentNode;
      } else if (currentNode.children && currentNode.children.length > 0) {
        const res = getNthKeyNodes(nodeKey, currentNode.children);
        if (res !== null) return { ...currentNode, children: [res] };
      }
    }
  } catch (error) {
    console.log("error ===>", error);
  }
  return null;
};

const mergeSelectedNodes = (
  selectedNodeKey: any,
  selectedNode: any,
  result: any[]
): any[] => {
  const updatedResult = result.map((node) => {
    if (node.id === selectedNodeKey || node.id === selectedNode.id) {
      const updatedChildren = selectedNode.children && selectedNode.children.length > 0 ? selectedNode.children.reduce(
        (acc: any[], child: any) => {
          const existingChildIndex = acc.findIndex(
            (n: any) => n.id === child.id
          );
          if (existingChildIndex === -1) {
            acc.push({
              ...child,
              children: child.children ? child.children : [],
            });
          } else {
            acc[existingChildIndex] = {
              ...acc[existingChildIndex],
              children: mergeNodes(
                child.children,
                acc[existingChildIndex].children
              ).sort((a: { id: number; }, b: { id: number; }) => a.id - b.id),
            };
          }
          return acc;
        },
        [...node.children]
      ) : [];

      return {
        ...node,
        children: updatedChildren,
      };
    }
    return node;
  });

  const nodeExists = updatedResult.some(
    (node) => node.id === selectedNodeKey || node.id === selectedNode.id
  );
  if (!nodeExists) {
    updatedResult.push(selectedNode);
  }
  return updatedResult;
};

const mergeNodes = (newNodes: any[], existingNodes: any[]): any[] => {
  const mergedNodes = [...existingNodes];
  try {
    newNodes.forEach((newNode) => {
      const existingNodeIndex = existingNodes.findIndex(
        (node: any) => node.id === newNode.id
      );
      if (existingNodeIndex === -1) {
        mergedNodes.push(newNode);
      } else {
        mergedNodes[existingNodeIndex].children = mergeNodes(
          newNode.children || [],
          existingNodes[existingNodeIndex].children || []
        );
      }
    });
  } catch (error) {
    console.log("error ===>", error);
  }
  return mergedNodes;
};

const getSelectedNode = (nodes: any[], keyToRemove: any): any => {

    const tempResult: any[] = [];
    for (let i = nodes.length - 1; i >= 0; i--) {
      const node = nodes[i];
      if (
        node.id === keyToRemove ||
        (node.children &&
          node.children.some((child: { id: any }) => child.id === keyToRemove))
      ) {
        tempResult.push(node);
      } else if (node.children) {
        const children = getSelectedNode(node.children, keyToRemove);
        if (children.length > 0) {
          tempResult.push(node);
        }
      }
    }
  
    return tempResult;
  };

const getSelectedResultNode = (selectedFullNode:any[], selectedKey: any) : any[] =>{
    debugger
    Array.isArray(selectedFullNode )&& selectedFullNode.forEach((node: any) => {
      if (node.id === selectedKey) {
        return selectedFullNode;
      } else if (node.children && node.children.length > 0) {
        node.children.forEach((child: any) => {
          if (child.id === selectedKey) {
            return child;
          } else {
            getSelectedResultNode(child, selectedKey);
          }
        });
      }
    });
    return [];
}
