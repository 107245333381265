import { TextField } from "@mui/material";

interface Props{
    id: string;
    name:string;
    type:string; 
    className:string;
    value?:string | number | null;
    defaultValue?: any;
    handleChange?:(e:React.ChangeEvent<HTMLInputElement>) => void;
    error?:any;
    readonly?:boolean;
    disabled?:boolean;
    shrink?:boolean;
    required?:boolean;
    label:string;
    handleBlur?:()=>void
    handleKeyPress?:(e:any) => void
    handleOnClick?:()=>void
    multiline?:boolean;
    rows?:number;
    maxLength?: number | null;
    minValue?: string | number | null;
    autoComplete?:string;
    startAdornment?:any;
    endAdornment?:any;
    fontWeight?:string;
}

const InputField = ({id, name, type, value, defaultValue, className, handleChange, error, readonly, required, label, 
  handleBlur, multiline, rows, maxLength = null, autoComplete, handleKeyPress, handleOnClick, minValue = null, disabled, shrink, startAdornment, endAdornment,fontWeight} : Props) => {
  return (
    <div>
      <TextField
        className={className}
        required={required}
        id={id}
        name={name}
        type={type}
        defaultValue={defaultValue}
        label={label}
        inputProps={{maxLength: maxLength, min:minValue}}
        value={value}
        onChange={handleChange}
        error={error ? true : false}
        helperText={error}
        onBlur={handleBlur}
        onFocus={handleBlur}
        onKeyDown={handleKeyPress}
        onClick={handleOnClick}
        size="small"
        disabled={disabled}
        InputProps={{
          readOnly: readonly,
          startAdornment: startAdornment,
          endAdornment: endAdornment,
          style:{
            fontWeight:fontWeight,
            background: readonly ? '#eee' : 'white',
            pointerEvents: readonly ? 'none' : 'all'
          },
        }}
        InputLabelProps={{
          shrink: shrink,
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "14px",
          },
        }}
        multiline={multiline}
        rows={rows || 3}
        autoComplete={autoComplete ? autoComplete : ""}
        placeholder={shrink ? label : ""}
      />
    </div>
  );
}
export default InputField
