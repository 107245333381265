import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import { setActiveStepAction, setColorTheme} from '../actions/actions';

const initialState: any = {
    activeStep: 0,
    color: {
      lightColor: "#ffff",
      mediumColor: "#5d678d",
      darkColor: "#19255c",
    },
};

export default createReducer<any, any>(initialState)
    .handleAction(setActiveStepAction, (state: any, { payload }: any) => {
        return produce(state, (draft: { activeStep: any; }) => {
            draft.activeStep = payload;
        });
    })
    .handleAction(setColorTheme, (state: any, { payload }: any) => {
        return produce(state, (draft: { color: any; }) => {
            switch (payload) {
                case "System Admin":
                  draft.color = {
                    lightColor: "#cfe0ff",
                    mediumColor: "#5d678d",
                    darkColor: "#19255c",
                  };
                  break;
                case "Organization Admin":
                  draft.color = {
                    lightColor: "#ffff",
                    mediumColor: "#5d678d",
                    darkColor: "#19255c",
                  };
                  break;
                default:
                  draft.color = {
                    lightColor: "#ffff",
                    mediumColor: "#5d678d",
                    darkColor: "#19255c",
                  };
                  break;
              }
        });
    });