import { Table, TableCell, TableFooter, TableRow, styled } from "@mui/material";
import {
  TablePagination,
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { selectThemeColor } from "../../redux/selectors/selectors";
import { useSelector } from "react-redux";
import { StyledTableCell } from "../../customeTheme";

interface Props {
  headCells: any[];
  rows: any[];
  page: any;
  rowsPerPage: any;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}
const CustomTablePagination = ({
  rows,
  headCells,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}: Props) => {
  const color = useSelector(selectThemeColor);
  const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
        width: 100%; 
        & .${classes.spacer} {
          display: none;
        }
      
        & .${classes.toolbar}  {
          display: flex;
          justify-content: space-between; /* Ensure space between elements */
          align-items: center;
          gap: 16px; /* Increase gap for better spacing */
          padding: 4px 0;
          width: 100%;
      
          @media (min-width: 768px) {
            flex-direction: row;
          }
        }
      
        & .${classes.selectLabel} {
          margin: 0;
          color: ${color.darkColor};
          font-family: 'SenticosansDTCond Thins';
          font-size: 14px;
        }
      
        & .${classes.select}{
          font-family: 'SenticosansDTCond Thins';
          padding: 2px 0 2px 4px;
          border: 1px solid ${color.darkColor};
          border-radius: 6px; 
          background-color: transparent;
          color: ${color.darkColor};
          transition: border-color 100ms ease;
          font-size: 14px;
      
          &:hover {
            border-color: ${color.mediumColor};
          }
        }
      
        & .${classes.displayedRows} {
          margin: 0;
      
          @media (min-width: 768px) {
            margin-left: auto;
          }
        }
      
        & .${classes.actions} {
          display: flex;
          gap: 12px; /* Adjust gap for better spacing */
          width: auto;
        }
      
        & .${classes.actions} > button {
          display: flex;
          align-items: center;
          padding: 0;
          border: 1px solid transparent;
          border-radius: 50%;
          background-color: transparent;
          color: ${color.darkColor};
          transition: all 120ms ease;
      
          > svg {
            font-size: 22px;
          }
      
          &:hover {
            background-color: transparent;
            border-color: ${color.lightColor};
          }
      
          &:focus {
            outline: 3px solid ${color.lightColor};
            border-color: ${color.lightColor};
          }
      
          &:disabled {
            opacity: 0.3;
            &:hover {
              border: 1px solid ${color.lightColor};
              background-color: transparent;
            }
          }
        }
        `
  );

  return (
   <Table className="table">
     <TableFooter>
      <TableRow>
        <StyledTableCell colSpan={headCells.length} size="small">
          <CustomTablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            colSpan={headCells.length}
            slotProps={{
              select: {
                "aria-label": "rows per page",
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
                slots: {
                  firstPageIcon: FirstPageRoundedIcon,
                  lastPageIcon: LastPageRoundedIcon,
                  nextPageIcon: ChevronRightRoundedIcon,
                  backPageIcon: ChevronLeftRoundedIcon,
                },
              },
            }}
          />
        </StyledTableCell>
      </TableRow>
    </TableFooter>
   </Table>
  );
};

export default CustomTablePagination;
