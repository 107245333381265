import * as Yup from "yup";

export const SubmitBidValidateSchema = Yup.object().shape({
  selectedTradeIds: Yup.array()
    .of(Yup.number())
    .min(1, "At least one trade must be selected")
    .required("Required"),
});

export const AddSubValidationSchema = (rows: any[]) => {
  return Yup.object({
    orgName: Yup.string().required("Required!"),
    firstName: Yup.string().required("Required!"),
    mobileNo: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \-]*)|(\\([0-9]{2,3}\\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .trim()
      .required("Required!"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required!")
      .trim()
      .test("is-unique", "Email already exists", function (value) {
        const { createError } = this;
        if (
          rows.some((row) => row.email.toLowerCase() === value.toLowerCase())
        ) {
          return createError({ message: "Email already exists" });
        }
        return true;
      }),
  });
};

export const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required!").trim(),
});

export const CreateUserValidationSchema = Yup.object({
  firstName: Yup.string().required("Required!").trim(),
  lastName: Yup.string().required("Required!").trim(),
  email: Yup.string().email("Invalid email").required("Required!"),
  phoneNo: Yup.string()
    .matches(
      /^(?:\s?)?(\d{3})[-\s.]?(\d{3})[-\s.]?(\d{4})$/,
      "Phone number is not valid"
    )
    .trim()
    .required("Required!"),
  isActive: Yup.boolean(),
});