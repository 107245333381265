export class DataUtil {
  static createNewBlocAndFileName(
    file: any,
    index: number
  ): { blob: Blob; name: string } {
    try {
      return {
        blob: new Blob([file.files[index]], { type: file.files[index].type }),
        name: file.name,
      };
    } catch (e) {
      console.log('e===>', e)
      return { blob: new Blob([file], { type: file.type }), name: file.name };
    }
  }

  static createAttachmentFile = (files: FileList | null | File[]) => {
    let fileArray: {
      name: string;
      files: File[];
    }[] = [];

    if (files) {
      Array.from(files).forEach((file: any) => {
        fileArray.push({
          name: file.name,
          files: Array.from(files),
        });
      });
    }
    return fileArray;
  };

  static createTreeStructuredData = (data: any) => {
    return (data && data.map((item: any) => ({
        id: item.id,
        label: item.name,
        parenttrade: item.parenttrade,
        tradeCode: item.tradeCode,
        children: item.subDivisionList
          ? getChildrenList(item.subDivisionList)
          : []
      }))
    );
  };

  static setTimeoutPromiseFunction = (delay: number): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (typeof delay !== 'number' || delay < 0) {
        return reject(new Error('Delay must be a non-negative number.'));
      }
  
      const timerId = setTimeout(() => {
        resolve();
        clearTimeout(timerId);
      }, delay);
    });
  }

  static createStringAvatar = (userName: string, color : any) => {
    const localStorageName = localStorage.getItem("name") ;
    if (!userName) {
      return {
        sx: {
          bgcolor: color.lightColor,
          width: 30, height: 30 ,
        },
        children: `NA`,
      };
    }
    const bgColor:any = userName === localStorageName ? color.darkColor : color.mediumColor;
    console.log(bgColor,"bgcol");
    return {
      sx: {
        bgcolor: bgColor,
        width: 24, height: 24 
      },
      children: `${userName[0][0]}`,
    };
  };

  static isArrayAndNotEmpty = (array: any)=>{
    return Array.isArray(array) && array.length > 0;
  }
  

}


const getChildrenList: any = (items: any[]) => {
  return items.map((item) => ({
    id: item.id,
    label: item.name,
    parenttrade: item.parenttrade,
    tradeCode: item.tradeCode,
    children: item.subDivisionList ? getChildrenList(item.subDivisionList) : [],
  }));
};

