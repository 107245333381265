import GlobalHeader from "../GlobalHeader";
import { CustomTable } from "../DataTable";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { exploreOpprtunitiesheaderCells } from "../../utils/const";
import { useEffect, useState } from "react";
import { getExploreOpportunities } from "../../utils/api";
import { cityNames } from "../../utils/const";
import SelectInputField from "../SelectInputField/selectInputField";

const style = [
  { width: "100%", height: "60vh", overflowX: "hidden", overflowY: "scroll" },
];

const ExploreOpportunities = () => {

  const [exploreOpportunitiesRowData, setExploreOpportunitiesRowData] = useState<any>([]);
  const [city, setCity] = useState<string>("");

  useEffect(() => {
    getExploreOpportunities(" ", "", undefined).then((resp) => {
      const data = resp.data;
      setExploreOpportunitiesRowData(data);
    }).catch((e) => {
      console.log('e error:>> ', e);
    })
  }, []);
 
  const handleSearch = ()=>{
    getExploreOpportunities("",city,undefined)
    .then((resp:any)=>{
      const data = resp.data;
      setExploreOpportunitiesRowData(data);
    }).catch((e) => {
      console.log('e error:>> ', e);
    })
  }

  return (
    <>
      <div
        className="d-flex justify-content-start mb-2"
        style={{ background: "#19255c" }}
      >
        <img
          src="BW logo-blue bg-svg.svg"
          width="310"
          height="56"
          alt="Login page"
          onError={(e) => {
            const img = e.target as HTMLImageElement;
            img.src = "image-not-found.png";
          }}
        />
      </div>
      <GlobalHeader title="Explore Opportunities" isLogoVisible />
      <div className="container-fluid">
        <div className="row">
          <div className="col-2">
            <SelectInputField
              id="city"
              name="city"
              handleChange={(e: any) => {
                setCity(e.target.value);
              }}
              label="Select City"
              menuList={cityNames}
            />
          </div>
          <div className="col-3 offset-7 d-flex justify-content-end">
            <Button
              className="btn col-6 col-md-6 col-sm-6 mt-1"
              style={{ backgroundColor: "gray", color: "white" }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
        </div>
        <div className="row mt-3 explore-opportunity">
          <CustomTable
            headCells={exploreOpprtunitiesheaderCells}
            rows={exploreOpportunitiesRowData}
            style={style}
          />
        </div>
      </div>
    </>
  );
};

export default ExploreOpportunities;
