import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, InputAdornment, Tab } from "@mui/material";
import Modal from "react-modal";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { SelectedFilesType, TradeRow } from "../../utils/types";
import { getInvitetDocuments } from "../../utils/api";
import AttachFileList from "../AttachFileList/attachFileList";
import { selectProjectId } from "../../redux/selectors/selectors";
import InputField from "../InputField";
import ReactQuill from "react-quill";
import { VisuallyHiddenInput } from "../../customeTheme";
import { TabContext, TabList } from "@mui/lab";
import UseFormikValidation from "../../hooks/useFormValidation";
import { SubmitBidValidateSchema } from "../../utils/validateSchemaUtil";
import { ProjectDocumentTable } from "../DataTable";
import { SubmitBidAddendaHeader } from "../../utils/const";

type InviteBidDocType = {
  addendaNumber: number;
  projectDocId: number;
  status: string | null;
};

type InviteBidRespType = {
  bidAmount: number;
  scope: string;
  isSubmit: string;
  invitationId: number;
  tradeCode: string;
  tradeId: number;
  inviteBidDocs: InviteBidDocType[];
  attachedBidFiles: any | null;
};

// Example of an object matching the type
const initialInviteBidResponse: InviteBidRespType = {
  bidAmount: 0,
  scope: "",
  isSubmit: "",
  invitationId: -1,
  tradeCode: "",
  tradeId: -1,
  inviteBidDocs: [],
  attachedBidFiles: null,
};
interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleBidSubmit: (status: string, values: any, submitBidFileRequest:SelectedFilesType) => void;
  isLoading: boolean;
  tradeRows: TradeRow[];
}

const BidSubmitModal = ({
  isOpen,
  isLoading,
  handleBidSubmit,
  handleClose,
  tradeRows,
}: Props) => {
  const [inviteBidResp, setInviteBidResp] = useState<InviteBidRespType[]>([
    initialInviteBidResponse,
  ]);
  const [isDisabledTradeCheck, setIsDisabledTradeCheck] = useState(false);
  const [inviteBidsDoc, setInviteBidsDoc] = useState<InviteBidDocType[]>([]);
  const [isSelectAllTrade, setIsSelecteAllTrade] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [submitBidFileRequest, setSubmitBidFileRequest] = useState<SelectedFilesType>({files:[]});
  const [tabValue, setTabValue] = useState<number>(-1);

  useEffect(() => {
    if (isOpen) {
      fetchInviteDocApi();
      setTabValue(tradeRows.length > 0 ? tradeRows[0].id : -1);
    }
  }, [isOpen]);

  const projectId = useSelector(selectProjectId);

  const initialValues = {
    selectedTradeIds: [],
    bidAmount: 0,
    scop: "",
    status: "",
    files: [],
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldValue,
    handleReset,
    handleChange,
  } = UseFormikValidation<any>({
    initialValues,
    validationSchema: SubmitBidValidateSchema,
    validateOnMount: true,
  });

  const tab = inviteBidResp.map((tab: InviteBidRespType) => {
    return (
      <Tab
        className="border ms-1 mt-1"
        label={tab.tradeCode}
        value={tab.invitationId}
        onClick={() => handleSelectTradeId(tab.invitationId)}
        sx={{
          background:  values.selectedTradeIds.some(
            (id: number) => id === tab.invitationId
          )
            ? "lightgreen !important" : tab.isSubmit === null ?
             "orange !important" : "lightgrey !important",
        }}
        title={values.selectedTradeIds.some(
          (id: number) => id === tab.invitationId
        ) ? "Bid Selected" : tab.isSubmit ? tab.isSubmit : 'Not Submitted'}
      />
    );
  });

  const fetchInviteDocApi = () => {
    getInvitetDocuments("", projectId, undefined)
      .then((response) => {
        if (response && response.data) {
          setInviteBidResp(response.data);
          setInviteBidsDoc(response.data[0].inviteBidDocs);
          setFieldValue("bidAmount", response.data[0].bidAmount);
          setFieldValue("scope", response.data[0].scope);
          const tempList = response.data.map((data : InviteBidRespType) => data.invitationId)
          setFieldValue("selectedTradeIds", tempList);
          setIsSelecteAllTrade(true);
        }
        // setInviteBidsDoc(response.data.inviteBidDocs);
        // const tradeList = response.data.tradeIds
        //   .filter((trade: any) => trade && trade.id)
        //   .map((trade: any) => trade.id);
        // if (tradeList.length === 0) {
        //   setIsSelecteAllTrade(true);
        //   setIsDisabledTradeCheck(true);
        // } else {
        //   setIsDisabledTradeCheck(false);
        // }
        // setSelectedTradeId(tradeList);
        // setIsSelecteAllTrade(true);
      })
      .catch((error: any) => {
        console.log(error);
        setInviteBidsDoc([]);
        setFieldValue("selectedTradeIds", []);
      });
  };

  const handleSelectTradeId = (id: any) => {

    setTabValue(id);
    const bidIndex = inviteBidResp.findIndex(
      (inviteBid: InviteBidRespType) => inviteBid.invitationId === id
    );
    const selectedBid = inviteBidResp[bidIndex];
    if (bidIndex !== -1) {
      setInviteBidsDoc(inviteBidResp[bidIndex].inviteBidDocs);
      setFieldValue("bidAmount", selectedBid.bidAmount);
      setFieldValue("scope", selectedBid.scope);
    } else {
      setInviteBidsDoc([]);
      setFieldValue("bidAmount", 0.0);
      setFieldValue("scope", "");
    }

    const list = values.selectedTradeIds.filter(
      (tradeId: any) => tradeId === id
    );
    if (list.length === 0) {
      let trade: any[] = [...values.selectedTradeIds, id];
      setFieldValue("selectedTradeIds", trade);
      if (tradeRows.length === trade.length) {
        setIsSelecteAllTrade(true);
        setFieldValue("selectedTradeIds", trade);
      }
    } else {
      let trade: any[] = values.selectedTradeIds.filter(
        (trade: any) => trade !== id
      );
      setFieldValue("selectedTradeIds", trade);
      if (trade.length === 0 || tradeRows.length > trade.length) {
        setIsSelecteAllTrade(false);
      }
    }
  };

  const handleFileInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = "";
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log("files", files);
    if (files) {
      console.log('files', files)
      setSubmitBidFileRequest({
        ...submitBidFileRequest,
        files: handleSetAttachMent(files) ,
      });
      setSelectedFiles((prevRows: any) => [
        ...prevRows,
        ...handleSetAttachMent(files),
      ]);
    }
  };

  const handleSetAttachMent = (files: FileList | null | File[]) => {
    let fileArray: {
      name: string;
      files: File[];
    }[] = [];

    if (files) {
      Array.from(files).forEach((file: any) => {
        fileArray.push({
          name: file.name,
          files: Array.from(files),
        });
      });
    }
    return fileArray;
  };

  const handleRemoveSelectedFile = (index: number) => {
    let removeSelectedFiles: File[] = [...selectedFiles].filter(
      (file: File, fileIndex: number) => fileIndex !== index
    );
    setSubmitBidFileRequest({
      ...submitBidFileRequest,
      files: handleSetAttachMent(removeSelectedFiles),
    });
    setSelectedFiles(removeSelectedFiles);
    setFieldValue("files",removeSelectedFiles);
  };

  return (
    <Modal
      id="submitBid"
      isOpen={isOpen}
      style={{
        content: {
          width: "60vw",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "auto",
          transform: "translate(-50%, -50%)",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
          opacity: "1",
          background: "#f8f9fa",
          zIndex: "0",
        },
      }}
      ariaHideApp={false}
    >
      <div className="modal-header border-bottom">
        <div className="col-12 d-flex justify-content-end">
          <FontAwesomeIcon
            className="btn-close pointer"
            icon={faClose}
            size="lg"
            onClick={() => {
              handleClose();
              handleReset();
              setInviteBidsDoc([]);
              setSubmitBidFileRequest({ files: [] });
            }}
          />
        </div>
      </div>
      <div
        className="modal-body"
        style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}
      >
        <div className="mt-2">
          <div className="row gx-0 mt-2">
            <label className="col-1 text-center mt-1">Trades:</label>
            <div
              className="col-9  p-0"
              style={{
                width: "40vw",
                overflowX: "auto",
                whiteSpace: "nowrap",
              }}
            >
              <TabContext value={tabValue}>
                <TabList
                  aria-label=""
                  sx={{
                    color: inviteBidResp.some(
                      (bid) =>
                        bid.tradeId === tabValue &&
                        bid.isSubmit === "Bid Submitted"
                    )
                      ? "green"
                      : "dark",
                    borderRadius: "4px",
                    width: "max-content",
                    "& .MuiTabs-indicator": {
                      backgroundColor: inviteBidResp.some(
                        (bid) =>
                          bid.tradeId === tabValue &&
                          bid.isSubmit === "Bid Submitted"
                      )
                        ? "darkgreen"
                        : "black",
                    },
                  }}
                  orientation="horizontal"
                >
                  {tab}
                </TabList>
              </TabContext>
            </div>
            <div className="col-2 py-3 ">
              <Checkbox
                className="p-0"
                aria-label="Is Select All Trade"
                checked={isSelectAllTrade}
                onChange={() => {
                  let trade: any[] = [];
                  tradeRows.forEach((element) => {
                    if (
                      !isSelectAllTrade 
                    ) {
                      trade.push(element.id);
                    }
                  });
                  setFieldValue("selectedTradeIds", trade);
                  setIsSelecteAllTrade(!isSelectAllTrade);
                }}
                size="small"
                disabled={isDisabledTradeCheck}
              />
              <span className="size-xs">Select All</span>
            </div>
          </div>
          <div className="row gx-0 mt-3">
            <div className="container">
              <ProjectDocumentTable
                headers={SubmitBidAddendaHeader}
                rows={inviteBidsDoc}
                toShowPagination={false}
                isEdit={false}
                maxHeight={150}
              />
            </div>
          </div>
          <div className="row gx-0 mt-3">
            <div className="col-3">
              <InputField
                id="bidAmount"
                name="bidAmount"
                type="text"
                className="form-control"
                label="Bid Amount"
                value={
                  values.bidAmount && /^\d*\.?\d{0,2}$/.test(values.bidAmount)
                    ? values.bidAmount
                    : 0.0
                }
                startAdornment={
                  <InputAdornment position="start" disablePointerEvents>
                    $
                  </InputAdornment>
                }
                handleChange={handleChange}
              />
            </div>
          </div>

          <div className="row gx-0">
            <p className="col-2">Scope:</p>
            <div className="col-12">
              <ReactQuill
                theme="snow"
                placeholder="Scope....."
                defaultValue={values.scope}
                value={values.scope}
                onChange={(content) => setFieldValue("scope", content)}
              />
            </div>
          </div>
        </div>
        <div className="row col-6 offset-3">
          <Button
            className="btn"
            component="label"
            role={undefined}
            variant="text"
            tabIndex={-1}
            size="small"
          >
            <AttachFileIcon className="fs-4" color="inherit" />
            Attach files
            <VisuallyHiddenInput
              multiple
              type="file"
              onChange={handleFileChange}
              onClick={handleFileInputClick}
              accept="application/pdf"
            />
          </Button>
        </div>
        <AttachFileList
          selectedFiles={submitBidFileRequest.files}
          handleRemoveSelectedFile={handleRemoveSelectedFile}
        />
      </div>

      <div className="row mt-1 gx-0">
        <div className="col-4">
          <Button
            className="btn col-12"
            onClick={(e) => {
              e.preventDefault();
              console.log("errors.selectedTradIds", errors.selectedTradIds);
              setFieldValue("status", "Bid Submitted");
              handleBidSubmit("Bid Submitted", values, submitBidFileRequest);
              handleReset();
              setInviteBidsDoc([]);
              setSubmitBidFileRequest({ files: [] });
            }}
            disabled={!isValid}
          >
            Submit
          </Button>
        </div>
        <div className="col-4">
          <Button
            className="btn col-12"
            onClick={(e) => {
              e.preventDefault();
              setFieldValue("status", "Draft");
              handleBidSubmit("Draft", values, submitBidFileRequest);
              handleReset();
              setInviteBidsDoc([]);
              setSubmitBidFileRequest({ files: [] });
            }}
          >
            Save
          </Button>
        </div>
        <div className="col-4">
          <Button
            className="btn col-12"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              handleReset();
              setInviteBidsDoc([]);
              setSubmitBidFileRequest({ files: [] });
            }}
          >
            Cancel
          </Button>
          {errors.selectedTradIds && (
            <span className="row">{errors.selectedTradIds as any}</span>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BidSubmitModal;
