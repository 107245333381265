import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  styled,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { InviteBidDetailsInfoType, InviteSubContState } from "../../../utils/types";
import { selectThemeColor } from "../../../redux/selectors/selectors";
import InputField from "../../InputField";
import { CustomTable } from "../../DataTable";
import { getSubmitProjectBidsDetails, sendInviteSubContractor } from "../../../utils/api";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import BidInviteModal from "../../BidInviteModal/bidInviteModal";
import SubmitBidDetailsModal from "../../SubmitBidDetailsModal/submitBidDetailsModal";
import { BidsTableHeaderCells, ModalStyle } from "../../../utils/const";
import UseFormikValidation from "../../../hooks/useFormValidation";
import { AddSubValidationSchema } from "../../../utils/validateSchemaUtil";
import { DataUtil } from "../../../utils/dataUtils";

const defaultFormData: InviteSubContState = {
  orgName: "",
  name: "",
  phoneNo: "",
  email: "",
};
interface Props {
  headerCells: any;
  node?: any;
  index?: any;
  isEdit: any;
  isView: boolean;
  handleSelectedRow?: (row: any) => void;
  refreshSubList?: (email : string) => void;
  handleExpandChange: (index: any) => (event: React.SyntheticEvent, isExpanded: boolean) => void
  projectId:any;
  expanded:string;
}

const defaultBidStatusType = {
  invited: 0,
  viewed: 0,
  bidding:0,
  rejected: 0,
  mayBe: 0,
  noResponse: 0,
  bidsReceive: 0,
}

const SubListDropdown = ({
  node,
  index,
  isEdit,
  projectId,
  refreshSubList,
  handleExpandChange,
  expanded = ""
}: Props) => {
  const color = useSelector(selectThemeColor);
  // const [expanded, setExpanded] = useState("1");
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [inviteSubConFormData, setInviteSubConFormData] =
    useState<InviteSubContState>(defaultFormData);
  const [counterObject, setCounterObject] = useState(defaultBidStatusType);
  const [rows, setRows] = useState(node.length === 0 ? [] : node.subsList);
  const [subContractorMails, setSubContractorMails] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openBidInviteModal, setBidOpenInviteModal] = useState<boolean>(false);
  const [openBidViewSubmitBidModal, setOpenBidViewSubmitBidModal] = useState<boolean>(false);
  const [inviteTypeList, setInviteTypeList] = useState<string[]>([]);
  const [inviteBidDetailsInfo, setInviteBidDetailsInfo] =  useState<InviteBidDetailsInfoType>({bidAmount:0,scope:'',inviteBidDocs:[],tradeIds:[],attachedBidFiles:[], bidTradeCode:node.tradeCode, bidInviteEmail:''})

  useEffect(() => {
    rows.forEach((element: any) => {
      switch (element.status) {
        case "Draft":
        case "Viewed":
          setCounterObject((prevStatus: any) => ({
            ...prevStatus,
            viewed: rows.filter(
              (value: { status: string }) => value.status === "Draft" || value.status === "Viewed"
            ).length,
          }));
          break;
        case "Invited":
          setCounterObject((prevStatus: any) => ({
            ...prevStatus,
            invited: rows.filter(
              (value: { status: string }) => value.status === "Invited"
            ).length,
          }));
          break;
        case "Rejected":
          setCounterObject((prevStatus: any) => ({
            ...prevStatus,
            rejected: rows.filter(
              (value: { status: string }) => value.status === "Rejected"
            ).length,
          }));
          break;
        case "May Be":
          setCounterObject((prevStatus: any) => ({
            ...prevStatus,
            mayBe: rows.filter(
              (value: { status: string }) => value.status === "May Be"
            ).length,
          }));
          break;
          
          case "Bid Submitted":
            setCounterObject((prevStatus: any) => ({
              ...prevStatus,
              bidsReceive: rows.filter(
                (value: { status: string }) => value.status === "Bid Submitted"
              ).length,
            }));
            break;
        default:
          setCounterObject((prevStatus: any) => ({
            ...prevStatus,
            noResponse: rows.filter(
              (value: { response: string; status: string }) =>
                value.response === "N" || value.response === null
            ).length,
          }));
          break;
      }
    });
  },[rows]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 12,
      fontWeight: "400",
      padding: "0.7 rem",
      width: 300,
      wordWrap: "break-word",
      overflowX: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
    },
  }));

  const initialValues = {
    orgName: "",
    firstName: "",
    mobileNo: "",
    email: "",
    response: "",
    status: "",
  }
  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    isValid,
    setFieldValue,
    handleChange,
    setFieldTouched,
    handleReset
  } = UseFormikValidation({ initialValues, validationSchema:AddSubValidationSchema(rows) });

  const handleClose = () => {
    setBidOpenInviteModal(false);
    setOpenBidViewSubmitBidModal(false);
  };

  const createInvitedSubConRow = () => {
    if (inviteSubConFormData) {
      const newRows: any = {
        orgName: values.orgName,
        firstName: values.firstName,
        mobileNo: values.mobileNo,
        email: values.email,
        response: "",
        status:"",

      };
      setRows([...rows, newRows]);
    }
  };

  const handleCheck = (row: any, checked: boolean) => {
    const { email , status} = row;
    let mailList = [...subContractorMails];
    const index = subContractorMails.indexOf(email);
    if (index !== -1) {
      mailList = mailList.filter(
        (mail) => !mail.toLowerCase().includes(email.toLowerCase())
      );
    } else {
      mailList = [...mailList, email];
    }
    setSubContractorMails(Array.from(new Set(mailList)));

  };

  const handleCheckAll = (rows : any) =>{
    let mailList = rows.map(
      (list: { email: any }) => list.email
    );
    setSubContractorMails(Array.from(new Set(mailList)));
  }

    const handleSendInvitation= ({subject , emailScope, files, inviteType} : any) => {
      setIsLoading(true);
      const formData = new FormData();
      const body = { projectId, projectTradeId: node.id, subContractorMails, subject,emailBody: emailScope,inviteType };
      formData.append('emailRequest', JSON.stringify(body));
      files.forEach((file : any, index:number) => {
        const { blob, name } = DataUtil.createNewBlocAndFileName(file, index);
        formData.append("emailAttachments", blob, name);
      });
      if (subContractorMails.length > 0) {
        sendInviteSubContractor("", undefined, formData)
          .then((response) => {
            console.log('response.message', response.message)
            setIsOpen(true);
            setIsLoading(false);
            if(response.status === "OK"){
              console.log(response,"invite ststaus");
              handleClose();
              refreshSubList!(subContractorMails[0]);
            }else{
              handleClose();
              setMessage(response.message);
            }
          })
          .catch((error: any) => {
            setIsOpen(true);
            handleClose();
            setMessage("Invite has already been sent.");
            setIsLoading(false);
          });
      } else {
        setIsOpen(true);
        setIsLoading(false);
        setMessage("Please Check invite mail");
        handleClose();
      }
    };

    const handleRowClick = (row: any, actionType: String, check: boolean) => {
      if (actionType === "viewBidDetails") {
        if (row["status"] === "Bid Submitted" || row["status"] === "Draft") {
          getSubmitProjectBidsDetails("",{projectBidId:row.bidId,email:row.email}, undefined)
            .then((response) => {
              setIsOpen(true);
              setIsLoading(false);
              if (response.status === "OK") {
                const data = response.data;
                setInviteBidDetailsInfo({
                  bidAmount:data.bidAmount,
                  scope : data.scope,
                  attachedBidFiles:data.attachedBidFiles,
                  tradeIds:[],
                  inviteBidDocs:[],
                  bidTradeCode:node.tradeCode, 
                  bidInviteEmail:row.email
                })
              }
            })
            .catch((error: any) => {
              handleClose();
            });
          setOpenBidViewSubmitBidModal(true);
        }
      } else {
          setSubContractorMails([row.email]);
          if (row.status === null || row.status === "") {
            let list: string[] = ["Bid Invite"];
            setInviteTypeList(list);
          } else {
            let list: string[] = [
              "Second Notice",
              "Addenda",
              "Job Walk",
              "Reminder",
              "Memo",
            ];
            setInviteTypeList(list);
          }
          setBidOpenInviteModal(true);
      }
      
    };

  return (
    <>
      <div className="mb-5">
        <Accordion
          key={index}
          expanded={expanded.includes(index)}
          aria-controls="panel1-content"
          id="panel1-header"
          onChange={handleExpandChange(index)}
        >
          <AccordionSummary
            className="mb-2 p-0"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-header"
            id="panel1-header"
            style={{ height: "10vh" }}
          >
            <TableHead key="table-head">
              <TableRow key="row" style={{ zIndex: 1 }}>
                <StyledTableCell key="addSubs">
                  <Button
                    className="btn main-btn"
                    onClick={() => {
                      setOpenInviteModal(true);
                    }}
                  >
                    <label className="size-xs">Add Sub</label>
                  </Button>
                </StyledTableCell>
                <StyledTableCell key="name">
                  <div
                    className="d-block"
                    style={{
                      width: 320,
                    }}
                  >
                    <span className="row text-primary me-1">
                      {node.tradeCode}
                    </span>
                    <span className="row"> {node.name}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="invited">
                  <div className="d-flex flex-column">
                    <span>Invited</span>
                    <span>
                      {!isEdit ? `(${counterObject.invited})` : "(0)"}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="viewed">
                  <div className="d-flex flex-column">
                    <span>Viewed</span>
                    <span>{!isEdit ? `(${counterObject.viewed})` : "(0)"}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="bidding">
                  <div className="d-flex flex-column">
                    <span>Bidding</span>
                    <span>
                      {!isEdit ? `(${counterObject.bidding})` : "(0)"}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="rejected">
                  <div className="d-flex flex-column">
                    <span>Rejected</span>
                    <span>
                      {!isEdit ? `(${counterObject.rejected})` : "(0)"}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="mayBe">
                  <div className="d-flex flex-column">
                    <span>May Be</span>
                    <span>{!isEdit ? `(${counterObject.mayBe})` : "(0)"}</span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="noRepsonse">
                  <div className="d-flex flex-column">
                    <span>No Response</span>
                    <span>
                      {!isEdit ? `(${counterObject.noResponse})` : "(0)"}
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell key="bidsReceived">
                  <div className="d-flex flex-column">
                    <span>Bids Received</span>
                    <span>
                      {!isEdit ? `(${counterObject.bidsReceive})` : "(0)"}
                    </span>
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </AccordionSummary>
          <AccordionDetails>
            <div className="d-flex justify-content-end"></div>
            <CustomTable
              headCells={BidsTableHeaderCells}
              rows={rows}
              handleCheck={handleCheck}
              handleCheckAll={handleCheckAll}
              onSelectRowClick={handleRowClick}
              onDeleteRowClick={() => null}
              toShowPagination={false}
              maxHeight={150}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <Modal
        id="addInviteSubContractor"
        isOpen={openInviteModal}
        style={ModalStyle}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-">
          <div
            className="modal-content"
            style={{ height: "380px", width: "450px" }}
          >
            <div className="modal-header border-bottom justify-content-center">
              <div className="opacity-70">
                <h4>Invite Sub Contractor For {node.tradeCode}</h4>
              </div>
            </div>
            <div
              className="d-grid shadow mt-3"
              style={{ backgroundColor: "#f3f6f7" }}
            >
              <div className="container">
                <div className="form-floating mt-3 mb-3">
                  <InputField
                    id="orgName"
                    name="orgName"
                    type="text"
                    value={values.orgName}
                    handleChange={handleChange}
                    className="form-control"
                    label="Organization Name"
                    error={errors.orgName}
                    handleBlur={() => setFieldTouched("orgName", true)}
                    required
                  />
                </div>
                <div className="form-floating mt-3 mb-3">
                  <InputField
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={values.firstName}
                    maxLength={25}
                    handleChange={handleChange}
                    className="form-control"
                    label="Name"
                    error={errors.firstName}
                    // error={props.isSubmit && props.touched.lastName && props.errors.lastName}
                    required
                  />
                </div>
                <div className="form-floating mt-3 mb-3">
                  <InputField
                    id="mobileNo"
                    name="mobileNo"
                    type="text"
                    maxLength={10}
                    value={values.mobileNo}
                    handleChange={handleChange}
                    className="form-control"
                    label="Phone Number"
                    error={errors.mobileNo}
                    required
                  />
                </div>
                <div className="form-floating mt-3 mb-3">
                  <InputField
                    id="email"
                    name="email"
                    type="email"
                    maxLength={50}
                    value={values.email}
                    handleChange={handleChange}
                    className="form-control"
                    label="Email"
                    error={errors.email}
                    // error={props.isSubmit && props.touched.email && props.errors.email}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row gx-0 ms-1 mt-2">
              <div className="col-6">
                <Button
                  className="btn col-10"
                  onClick={(e) => {
                    e.preventDefault();
                    handleReset();
                    setOpenInviteModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-6">
                <Button
                  className="btn col-10"
                  onClick={(e) => {
                    e.preventDefault();
                    if (isValid) {
                      setOpenInviteModal(false);
                      createInvitedSubConRow();
                    }
                  }}
                >
                  Add To List
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <BidInviteModal
        isOpen={openBidInviteModal}
        handleClose={handleClose}
        subMailsList={subContractorMails}
        handleSubmit={handleSendInvitation}
        isLoading={isLoading}
        tradeId={node.id}
        inviteTypeList={inviteTypeList}
      />
      <SubmitBidDetailsModal
        isOpen={openBidViewSubmitBidModal}
        handleClose={handleClose}
        subMailsList={subContractorMails}
        inviteBidDetailsInfo={inviteBidDetailsInfo}
      />
      <CustomizedSnackbar
        openSnackBar={isOpen}
        message={message}
        handleCloseSnackBar={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default SubListDropdown;
