import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { DELETE_DOCUMENT, UPLOAD_DOCUMENT, VIEW_DOCUMENT } from "./actions";
import { setDocumentDetails } from "../actions/projectFormActions";
import { getProjectDocuments, uploadProjectDocument } from "../../utils/api";

// function* watchUploadDocument(action : any){
//   const { type, privacyType, selectedDocRows, files } = action.payload;
//   let docs: any[] = [];

//   const isTypeExist = selectedDocRows.some(
//     (item: { docType: any }) => item.docType === type
//   );

//   if (isTypeExist) {
//     docs = selectedDocRows.map((item: any) => {
//       if (item.docType === type) {
//         return {
//           ...item,
//           privacyType: [...item.privacyType, privacyType],
//           files: [...item.files, ...files],
//           docName: [...item.docName, ...files.map((file: any) => file.name)],
//           size: [
//             ...item.size,
//             ...files.map((file: any) => (file.size / 1024).toFixed(2)),
//           ],
//         };
//       } else {
//         return item;
//       }
//     });
//   } else {
//     files.forEach((file: any, index: number) => {
//       if(index < 1){
//         docs = [
//           ...selectedDocRows,
//             {
//               docType: type,
//               files: [file],
//               privacyType: [privacyType],
//               docName: [file.name],
//               size: [(file.size / 1024).toFixed(2)],
//             },
          
//         ];

//       }else{
//         docs = selectedDocRows.map((item: any) => {
//           if (item.docType === type) {
//             return {
//               ...item,
//               privacyType: [...item.privacyType, privacyType],
//               files: [...item.files, ...files],
//               docName: [...item.docName, ...files.map((file: any) => file.name)],
//               size: [
//                 ...item.size,
//                 ...files.map((file: any) => (file.size / 1024).toFixed(2)),
//               ],
//             };
//           } else {
//             return {
//               type:type,
//               privacyType: [privacyType],
//               files: [files],
//               docName: [...files.map((file: any) => file.name)],
//               size: [
//                 ...files.map((file: any) => (file.size / 1024).toFixed(2)),
//               ],
//             };
//           }
//         });
//       }
//     });
  
//   }
//   // yield put(setDocumentDetails(docs))

// }
function* watchUploadDocument(action : any){
  const { formData } = action.payload;
  let docs : any = [];
  uploadProjectDocument("", undefined, formData)
  .then((response) => {
    if (!(response.status === 'OK')) {
      console.error("Failed to create project");
    }else{
      getProjectDocuments("", 1, undefined)
        .then((response) => {
          docs = response.data;
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
    // onUpload();
  })
  .catch((error) => {
    console.error("Error creating project:", error);
    // Handle error
  });
  yield put(setDocumentDetails(docs))

}
function* watchViewDocument(){
    console.warn("view Document saga called");
}
function* watchDeleteDocument(action:any){
    const {index, type, selectedDocRows} =  action.payload;

    const docs = selectedDocRows.reduce((acc: any[] | [], item: any) => {
      if (item.docType === type) {
        const newType: string[] = item.privacyType.filter(
          (_: any, deleteIndex: number) => deleteIndex !== index
        );
        if (newType.length === 0) {
          return acc;
        } else {
          return [
            ...acc,
            {
              ...item,
              privacyType: newType,
              files: item.files.filter(
                (_: any, deleteIndex: number) => deleteIndex !== index
              ),
              docName: item.docName.filter(
                (_: any, deleteIndex: number) => deleteIndex !== index
              ),
              size: item.size.filter(
                (_: any, deleteIndex: number) => deleteIndex !== index
              ),
            },
          ];
        }
      } else {
        return [...acc, item];
      }
    }, []);

      yield put(setDocumentDetails(docs))
    }


function* documentSaga() {

    yield takeEvery(UPLOAD_DOCUMENT, watchUploadDocument);
    yield takeEvery(VIEW_DOCUMENT, watchViewDocument);
    yield takeEvery(DELETE_DOCUMENT, watchDeleteDocument);


};

export default documentSaga;
