import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/selectors/selectors";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  Key,
} from "react";

interface Props {
  selectedFiles: any;
  handleRemoveSelectedFile: (index: number) => void;
}

const AttachFileList = ({ selectedFiles, handleRemoveSelectedFile }: Props) => {
  const color = useSelector(selectThemeColor);
  return (
    <>
      {selectedFiles.length > 0 ? (
        <div className="row overflow-hidden m-3">
          <ul className="list-group list-group-numbered">
            {selectedFiles.map((file: File, index: number) => (
              <li
                key={index}
                className="col-12 list-group-item d-flex m-2"
                style={{
                  height: "auto",
                  border: `3px dashed ${color.mediumColor}`,
                }}
              >
                <span className="col-10 p-0 ms-1">{file.name}</span>
                <FontAwesomeIcon
                  className="col-2 pointer"
                  icon={faRemove}
                  size="lg"
                  onClick={() => handleRemoveSelectedFile(index)}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AttachFileList;
