import * as Yup from "yup";
import { useFormik } from "formik";
import { OrganizationState } from "../../utils/types";
import { useState } from "react";

const Utils = () => {
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const InitialValues: OrganizationState = {
    orgName: "",
  licenseNumber: "",
  createdDate: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  status: "",
    
  };
  const ValidationSchema = Yup.object({
    orgName: Yup.string().required("Required!"),
    licenseNumber: Yup.string().required("Required!"),
    registeredDir: Yup.string().required("Required!"),
    email: Yup.string().email("Invalid email").required("Required!"),
  });
  
  const onSubmit = () => {
    setIsSubmit(true);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
  } = useFormik({
    initialValues: InitialValues,
    validationSchema: ValidationSchema,
    onSubmit,
  });

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
    isSubmit,
    onSubmit
  };
};

export default Utils;
